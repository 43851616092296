import React, { useEffect, useState } from 'react';
import { Heart, ShoppingBag, Eye, ArrowDownUp, X , Search, ArrowUpDown, Star, ListRestart, Plus, Minus, Filter , Check, ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react';
import { API_BASE_URL, S3_BASE_URL } from '../config/api';
import axios, { all } from "axios";
import { motion } from "framer-motion";
import { addItemToCart } from '../config/api';
import SareeIntro from '../customer/Components/About saree/SareeIntro';
import ListoutProduct from '../customer/Components/litoutProduct/ListoutProduct';
import SareeList from '../customer/Components/litoutProduct/ListoutProduct';
// Moved constants outside component
const INITIAL_CATEGORIES = [
  { id: 'kanchipuram', label: 'Kanchipuram', color: '#FFF' },
  { id: 'soft-silks', label: 'Soft-silks', color: '#000' },
  { id: 'mysore-silks', label: 'Mysore-silks', color: '#FFF' },
  { id: 'suit-set', label: 'Suit Set', color: '#FFF' }
];
const INITIAL_COLORS = [
  { name: 'red', value: 'red', apiValue: 'RED' },
  { name: 'pink', value: 'pink', apiValue: 'PINK' },
  { name: 'orange', value: 'orange', apiValue: 'ORANGE' },
  { name: 'yellow', value: 'yellow', apiValue: 'YELLOW' },
  { name: 'green', value: 'green', apiValue: 'GREEN' },
  { name: 'blue', value: 'blue', apiValue: 'BLUE' },
  { name: 'purple', value: 'purple', apiValue: 'PURPLE' },
  { name: 'brown', value: 'brown', apiValue: 'BROWN' },
  { name: 'black', value: 'black', apiValue: 'BLACK' },
  { name: 'white', value: 'white', apiValue: 'WHITE' },
  { name: 'gold', value: 'gold', apiValue: 'GOLD' },
  { name: 'silver', value: 'silver', apiValue: 'SILVER' },
  { name: 'cream', value: '#FFFDD0', apiValue: 'CREAM' },
];
const INITIAL_FABRIC = ['Cotton', 'Silk', 'Linen', 'Chiffon'];
const INITIAL_STYLE = ['Traditional', 'Modern', 'Casual', 'Formal'];
const INITIAL_ZARI = ['Gold', 'Silver', 'Copper'];
const INITIAL_PATTERN = ['Floral', 'Geometric', 'Solid', 'Striped'];
var INITIAL_PRODUCTS = [
  {
    id: 1,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Mysore-silks"
  },
  {
    id: 2,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Kanchipuramsilks"
  },
  {
    id: 3,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Kanchipuramsilks"
  },
  {
    id: 4,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Suit Set"
  },
  // Add more products as needed...
];

const FestiveEditShop = () => {
  // States
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedFabric, setSelectedFabric] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [selectedZari, setSelectedZari] = useState([]);
  const [allProducts, setAllProducts] = useState();
  const [selectedPattern, setSelectedPattern] = useState([]);
  const [priceRange, setPriceRange] = useState([1200, 17000]);
  const [wishlist, setWishlist] = useState(new Set());
  console.log(allProducts)

  const [sortOption, setSortOption] = useState("Default");
  const [showSortModal, setShowSortModal] = useState(false);
  // New states for load more functionality
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productsPerLoad] = useState(20);
  const [currentCount, setCurrentCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(true);
  const [isFilterOpen, setIsfilterOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(20);
  const [filteredProducts, setFilteredProducts] = useState([]);



  // Get and set product all products
  useEffect(() => {
    const getAllProducts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/products/allpublic`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        });

        const sortedAllProducts = response.data.body.productList.map((product) => {
          const imageUrls = product.imageUrls.sort((a, b) => {
            const aIndex = a.indexOf(product.skuNumber);
            const bIndex = b.indexOf(product.skuNumber);
            const aNum = parseInt(a.slice(aIndex + product.skuNumber.length + 1));
            const bNum = parseInt(b.slice(bIndex + product.skuNumber.length + 1));
            return aNum - bNum;
          });
          return { ...product, imageUrls };
        });

        setAllProducts(sortedAllProducts);
        setFilteredProducts(sortedAllProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllProducts();
  }, []);

  // Filter Apply
  useEffect(() => {
    const applyFilters = () => {
      if (!allProducts) return;

      let filtered = [...allProducts];

      // Filter by price range
      filtered = filtered.filter(product =>
        product.price >= priceRange[0] && product.price <= priceRange[1]
      );

      // Filter by categories
      if (selectedCategories.length > 0) {
        filtered = filtered.filter(product =>
          selectedCategories.some(category =>
            product.categoryName?.toLowerCase().includes(category.toLowerCase())
          )
        );
      }

      // Filter by colors
      if (selectedColors.length > 0) {
        filtered = filtered.filter(product =>
          selectedColors.some(color =>
            product.color?.toLowerCase() === color.toLowerCase()
          )
        );
      }

      // Filter by fabric
      if (selectedFabric.length > 0) {
        filtered = filtered.filter(product =>
          selectedFabric.some(fabric =>
            product.fabric?.toLowerCase().includes(fabric.toLowerCase())
          )
        );
      }

      // Filter by style
      if (selectedStyle.length > 0) {
        filtered = filtered.filter(product =>
          selectedStyle.some(style =>
            product.style?.toLowerCase().includes(style.toLowerCase())
          )
        );
      }

      // Filter by zari
      if (selectedZari.length > 0) {
        filtered = filtered.filter(product =>
          selectedZari.some(zari =>
            product.zari?.toLowerCase().includes(zari.toLowerCase())
          )
        );
      }

      // Filter by pattern
      if (selectedPattern.length > 0) {
        filtered = filtered.filter(product =>
          selectedPattern.some(pattern =>
            product.pattern?.toLowerCase().includes(pattern.toLowerCase())
          )
        );
      }
      filtered = filtered.sort((a, b) => {
        switch (sortOption) {
          case "Price: Low to High":
            return a.price - b.price;
          case "Price: High to Low":
            return b.price - a.price;
          default:
            return 0; // Default sorting (original order)
        }
      });

      setFilteredProducts(filtered);
      setCurrentPage(1);
    };

    applyFilters();
  }, [
    allProducts,
    selectedCategories,
    selectedColors,
    selectedFabric,
    selectedStyle,
    selectedZari,
    selectedPattern,
    priceRange,
    sortOption // Add sortOption to dependency array
  ]);

  const handleSortChange = (option) => {
    setSortOption(option);
  };


  // colors filter
  useEffect(() => {
    if (selectedColors.length > 0) {
      console.log('Selected Colors:', selectedColors);
      console.log('Filtered Products:', filteredProducts.map(p => ({
        id: p.id,
        colors: p.colors
      })));
    }
  }, [selectedColors, filteredProducts]);

  const handleColorSelection = (colorName) => {
    setSelectedColors(prev =>
      prev.includes(colorName)
        ? prev.filter(name => name !== colorName)
        : [...prev, colorName]
    );
  };
  const clearAllFilters = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setSelectedFabric([]);
    setSelectedStyle([]);
    setSelectedZari([]);
    setSelectedPattern([]);
    setPriceRange([1200, 17000]);
  };

  const clearAllFiltersMD = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setSelectedFabric([]);
    setSelectedStyle([]);
    setSelectedZari([]);
    setSelectedPattern([]);
    setPriceRange([1200, 17000]);
    setIsMobileFilterOpen(false)
  };

  useEffect(() => {
    if (filteredProducts.length > 0) {
      const initialProducts = filteredProducts.slice(0, productsPerLoad);
      setDisplayedProducts(initialProducts);
      setCurrentCount(initialProducts.length);
      setHasMore(filteredProducts.length > productsPerLoad);
    }
  }, [filteredProducts]);

  const handleLoadMore = () => {
    const nextProducts = filteredProducts.slice(
      currentCount,
      currentCount + productsPerLoad
    );
    setDisplayedProducts(prev => [...prev, ...nextProducts]);
    setCurrentCount(prev => prev + nextProducts.length);
    setHasMore(currentCount + nextProducts.length < filteredProducts.length);

  };


  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const toggleWishlist = (productId) => {
    setWishlist(prev => {
      const newWishlist = new Set(prev);
      if (newWishlist.has(productId)) {
        newWishlist.delete(productId);
      } else {
        newWishlist.add(productId);
      }
      return newWishlist;
    });
  };

  const [expandedSections, setExpandedSections] = useState({
    price: true,
    category: true,
    size: true,
    color: true
  });


  // Handler functions
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleSelectionChange = (item, setSelected) => {
    setSelected(prev =>
      prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
    );
  };

  const handlePriceChange = (e, index) => {
    const newValue = parseInt(e.target.value);
    setPriceRange(prev => {
      const newRange = [...prev];
      newRange[index] = newValue;
      return newRange;
    });
  };

  const getGradientStyle = (index) => {
    const percentage = ((priceRange[index] - 1200) / (17000 - 1200)) * 100;
    return {
      background: `linear-gradient(to right, 
        #4A90E2 0%, 
        #4A90E2 ${percentage}%, 
        #1ABC9C ${percentage}%, 
        #1ABC9C 100%)`
    };
  };

  const FiltersContent = () => (
    <div className="space-y-6">

      {/* Add filter count badge */}
      {/* <div className="px-4 py-2 bg-gray-100 rounded-lg">
        <p className="text-sm text-gray-600">
          Showing {filteredProducts.length} of {allProducts.length} products
        </p>
      </div> */}
      <FilterSection
        title="PRICE"
        expanded={expandedSections.price}
        onToggle={() => toggleSection('price')}
      >
        <div className="px-4 w-full">
          <div className="relative pt-6">
            {/* Base Track */}
            <div className="absolute w-full h-1 bg-gray-200 rounded-full" />

            {/* Min Price Slider */}
            <input
              type="range"
              min={1200}
              max={17000}
              value={priceRange[0]}
              onChange={(e) => handlePriceChange(e, 0)}
              className="absolute w-full -top-2 h-1 appearance-none rounded-full cursor-pointer transition-all duration-300"
              style={getGradientStyle(0)}
            />

            {/* Max Price Slider */}
            <input
              type="range"
              min={1200}
              max={17000}
              value={priceRange[1]}
              onChange={(e) => handlePriceChange(e, 1)}
              className="absolute w-full -top-2 h-1 appearance-none rounded-full cursor-pointer transition-all duration-300"
              style={getGradientStyle(1)}
            />

            {/* Custom Thumb Styles */}
            <style jsx>{`
          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            background: black;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease;
          }
          
          input[type='range']::-webkit-slider-thumb:hover {
            transform: scale(1.1);
          }
          
          input[type='range']::-moz-range-thumb {
            width: 16px;
            height: 16px;
            background: black;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease;
            border: none;
          }
          
          input[type='range']::-moz-range-thumb:hover {
            transform: scale(1.1);
          }
        `}</style>
          </div>

          {/* Price Display */}
          <div className="flex justify-between mt-8 text-sm text-gray-700">
            <span>₹{priceRange[0].toLocaleString()}</span>
            <span>₹{priceRange[1].toLocaleString()}</span>
          </div>
        </div>
      </FilterSection>

      <FilterSection
        title="CATEGORY"
        expanded={expandedSections.category}
        onToggle={() => toggleSection('category')}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-4">
          {INITIAL_CATEGORIES.map(category => (
            <label
              key={category.id}
              className={`px-4 py-2 border cursor-pointer transition-colors ${selectedCategories.includes(category.id)
                ? 'bg-black text-white'
                : 'bg-white text-black hover:bg-gray-50'
                }`}
            >
              <div className="flex items-center justify-between">
                <span className="text-sm">{category.label}</span>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => {
                    setSelectedCategories(prev =>
                      prev.includes(category.id)
                        ? prev.filter(id => id !== category.id)
                        : [...prev, category.id]
                    )
                  }}
                  className="hidden"
                />
              </div>
            </label>
          ))}
        </div>
      </FilterSection>
      <FilterSection title="FABRIC" expanded={expandedSections.fabric} onToggle={() => toggleSection('fabric')}>
        <div className="px-4">
          {INITIAL_FABRIC.map(fabric => (
            <label key={fabric} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedFabric.includes(fabric)} onChange={() => handleSelectionChange(fabric, setSelectedFabric)} />
              <span>{fabric}</span>
            </label>
          ))}
        </div>
      </FilterSection>

      <FilterSection title="STYLE" expanded={expandedSections.style} onToggle={() => toggleSection('style')}>
        <div className="px-4">
          {INITIAL_STYLE.map(style => (
            <label key={style} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedStyle.includes(style)} onChange={() => handleSelectionChange(style, setSelectedStyle)} />
              <span>{style}</span>
            </label>
          ))}
        </div>
      </FilterSection>

      <FilterSection title="ZARI" expanded={expandedSections.zari} onToggle={() => toggleSection('zari')}>
        <div className="px-4">
          {INITIAL_ZARI.map(zari => (
            <label key={zari} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedZari.includes(zari)} onChange={() => handleSelectionChange(zari, setSelectedZari)} />
              <span>{zari}</span>
            </label>
          ))}
        </div>
      </FilterSection>


      <FilterSection
        title="COLOUR"
        expanded={expandedSections.color}
        onToggle={() => toggleSection('color')}
      >
        <div className="grid grid-cols-1 gap-2 px-4">
          {INITIAL_COLORS.map(color => (
            <label
              key={color.name}
              className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-50 rounded"
            >
              <div className="flex items-center space-x-3">
                <span
                  className="w-5 h-5 rounded-full inline-block border border-gray-200"
                  style={{ backgroundColor: color.value }}
                ></span>
                <span className="text-sm">{color.name}</span>
              </div>
              <CustomCheckbox
                checked={selectedColors.includes(color.name)}
                onChange={() => handleColorSelection(color.name)}
                className="ml-2"
              />
            </label>
          ))}
        </div>
      </FilterSection>
    </div>
  );

  const FiltersContentMD = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    // Toggle the current section
    const toggleSection = (section) => {
      setExpandedSection((prev) => (prev === section ? null : section));
    };

    return (
      <div>
        {/* Price Filter */}
        <FilterSectionMD
          title="PRICE"
          expanded={expandedSection === "price"}
          onToggle={() => toggleSection("price")}
        >
          <div className="relative w-full pt-4">
            {/* Base Track */}
            <div className="absolute w-full h-1 bg-gray-200 rounded-full" />

            {/* Price Range Sliders */}
            {[0, 1].map((index) => (
              <input
                key={index}
                type="range"
                min={1200}
                max={17000}
                value={priceRange[index]}
                onChange={(e) => handlePriceChange(e, index)}
                className="absolute w-full h-1 appearance-none -top-2 rounded-full cursor-pointer transition-all duration-300"
                style={getGradientStyle(index)}
              />
            ))}

            {/* Thumb Styles */}
            <style jsx>{`
              input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 20px;
                height: 20px;
                background: black;
                border-radius: 50%;
                cursor: pointer;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                transition: transform 0.2s ease;
              }
              input[type="range"]::-webkit-slider-thumb:hover {
                transform: scale(1.1);
              }
            `}</style>
          </div>

          {/* Price Display */}
          <div className="flex justify-between mt-6 text-sm text-gray-700">
            <span>₹{priceRange[0].toLocaleString()}</span>
            <span>₹{priceRange[1].toLocaleString()}</span>
          </div>
        </FilterSectionMD>

        {/* Category Filter */}
        <FilterSectionMD
          title="CATEGORY"
          expanded={expandedSection === "category"}
          onToggle={() => toggleSection("category")}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {INITIAL_CATEGORIES.map((category) => (
              <label
                key={category.id}
                className={`flex items-center px-3 py-2 border rounded-lg cursor-pointer transition-colors ${selectedCategories.includes(category.id)
                  ? "bg-black text-white"
                  : "bg-white text-black hover:bg-gray-100"
                  }`}
              >
                <span className="text-sm">{category.label}</span>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.id)}
                  onChange={() =>
                    setSelectedCategories((prev) =>
                      prev.includes(category.id)
                        ? prev.filter((id) => id !== category.id)
                        : [...prev, category.id]
                    )
                  }
                  className="hidden"
                />
              </label>
            ))}
          </div>
        </FilterSectionMD>

        {/* Fabric Filter */}
        <FilterSectionMD
          title="FABRIC"
          expanded={expandedSection === "fabric"}
          onToggle={() => toggleSection("fabric")}
        >
          <div className="flex flex-wrap gap-2">
            {INITIAL_FABRIC.map((fabric) => (
              <label key={fabric} className="flex items-center space-x-2 cursor-pointer">
                <CustomCheckbox
                  checked={selectedFabric.includes(fabric)}
                  onChange={() => handleSelectionChange(fabric, setSelectedFabric)}
                />
                <span>{fabric}</span>
              </label>
            ))}
          </div>
        </FilterSectionMD>

        {/* Style Filter */}
        <FilterSectionMD
          title="STYLE"
          expanded={expandedSection === "style"}
          onToggle={() => toggleSection("style")}
        >
          <div className="flex flex-wrap gap-2">
            {INITIAL_STYLE.map((style) => (
              <label key={style} className="flex items-center space-x-2 cursor-pointer">
                <CustomCheckbox
                  checked={selectedStyle.includes(style)}
                  onChange={() => handleSelectionChange(style, setSelectedStyle)}
                />
                <span>{style}</span>
              </label>
            ))}
          </div>
        </FilterSectionMD>

        {/* Color Filter */}
        <FilterSectionMD
          title="COLOUR"
          expanded={expandedSection === "color"}
          onToggle={() => toggleSection("color")}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {INITIAL_COLORS.map((color) => (
              <label
                key={color.name}
                className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-50 rounded"
              >
                <div className="flex items-center space-x-3">
                  <span
                    className="w-5 h-5 rounded-full border"
                    style={{ backgroundColor: color.value }}
                  ></span>
                  <span className="text-sm">{color.name}</span>
                </div>
                <CustomCheckbox
                  checked={selectedColors.includes(color.name)}
                  onChange={() => handleColorSelection(color.name)}
                  className="ml-2"
                />
              </label>
            ))}
          </div>
        </FilterSectionMD>
      </div>
    );
  };

  // if (isLoading) {
  //   return (
  //     <div className="min-h-screen bg-white">
  //       <div className="h-[250px] py-6 text-white bg-black w-full flex justify-center items-center">
  //         <div className="flex justify-center flex-col items-center">
  //           <h1 className="text-4xl md:text-5xl font-bold">Summer Collection</h1>
  //           <p className="text-lg md:text-xl opacity-90">Discover our latest arrivals</p>
  //         </div>
  //       </div>

  //       <div className="max-w-7xl mx-auto px-4 py-8">
  //         <div className="flex gap-8">
  //           {/* Desktop Filters Sidebar - Skeleton */}
  //           <div className="hidden lg:block w-72 flex-shrink-0">
  //             <div className="space-y-6">
  //               {[1, 2, 3, 4].map((i) => (
  //                 <div key={i} className="h-40 bg-gray-200 rounded animate-pulse" />
  //               ))}
  //             </div>
  //           </div>

  //           {/* Product Grid - Skeleton */}
  //           <div className="flex-1">
  //             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
  //               {[1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12].map((i) => (
  //                 <ProductSkeleton key={i} />
  //               ))}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="min-h-screen bg-white">
      <div className="  h-auto py-6 text-white bg-black w-full flex justify-center items-center">
        <div className=" flex justify-center flex-col items-center ">
          <h1 className="text-xl md:text-5xl lg:text-5xl font-bold ">Summer Collection</h1>
          <p className="text-[11px] md:text-xl lg:text-xl  opacity-90">Discover our latest arrivals</p>
        </div>

      </div>
      < SareeIntro
        title="Timeless Elegance"
        description="Silk sarees are known for their luxurious feel and exquisite craftsmanship. From the intricate zari work of Banarasi silk sarees to the rich colors of Kanchipuram silk sarees, each piece is a testament to tradition and artistry. Silk sarees are not just garments; they are symbols of celebration and heritage, often treasured as family heirlooms. Whether worn for weddings or festivals, silk sarees always exude timeless elegance and make a statement of sophistication."
        backgroundImage="https://cliosilks.com/cdn/shop/articles/blog-Cover.jpg?v=1686996035"
      />



      {/* <div className="lg:hidden fixed border-t-2 flex justify-between items-center px-6  bottom-0 bg-white py-3 w-full z-40 ">
        <div>
          <button
            onClick={() => setIsMobileFilterOpen(true)}
            className={`flex items-center  ${isMobileFilterOpen ? " bg-button-green  text-white" : " bg-white text-black"} px-4 py-2 border border-gray-200 rounded`}

          >
            <Filter className="w-4 h-4" />
            <span>Filters</span>
          </button>
        </div>

        <div>
          <button
            onClick={() => setShowSortModal(true)}
            className={`flex items-center  ${sortOption ? " bg-button-green  text-white" : " bg-white text-black"} px-4 py-2 border border-gray-200 rounded`}

          >
            <Filter className="w-4 h-4" />
            <span>Sort</span>
          </button>
        </div>


      </div> */}

      <div className="  w-full lg:w-[80%] z-50 mx-auto px-4 py-8">

      {isMobileFilterOpen && (
        <div className='   w-full z-50 fixed min-h-[60vh] max-h-[60vh]  lg:hidden '>
         <TaneiraFilterSort/>
        </div>
      )}

        {/* Mobile Filter Drawer */}
        {/* {isMobileFilterOpen && (
  <div className="fixed inset-0 z-50 flex flex-col justify-end lg:hidden">

    <div
      className="relative bg-white rounded-t-lg shadow-lg overflow-y-auto max-h-[70vh] p-6 transform transition-transform duration-300 ease-in-out"
      style={{
        transform: isMobileFilterOpen ? "translateY(0)" : "translateY(100%)",
      }}
    >

      <div className="flex justify-between items-center border-b pb-4 mb-4">
        <h2 className="text-lg font-semibold text-gray-800">Filters</h2>
        <div className="flex space-x-4">

          <button
            onClick={clearAllFiltersMD}
            className="text-sm text-gray-600 hover:text-gray-900 transition hover:scale-105"
          >
            Clear All
          </button>


          <button
            onClick={() => setIsMobileFilterOpen(false)}
            className="text-gray-600 hover:text-gray-900 transition hover:scale-110"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
      </div>


      <FiltersContentMD />
    </div>
  </div>
)} */}


        {showSortModal && (
          <SortBy onSortChange={handleSortChange} />
          // <SortBy
          //   onClose={() => setShowSortModal(false)}
          //   onSortChange={(option) => {
          //     console.log('Selected sort option:', option);
          //     setShowSortModal(false);
          //   }}
          // />
        )}



        {!isFilterOpen && (
          <div className="border  border-black p-6 rounded-lg shadow-sm mb-8 hidden transition-all duration-800  lg:block">

            <div className="flex flex-wrap items-center space-x-4">
              <PriceRangeFilter value={priceRange} onChange={setPriceRange} />
              <TopFilter
                label="Category"
                options={INITIAL_CATEGORIES}
                selected={selectedCategories}
                onChange={setSelectedCategories}
              />
              <TopFilter
                label="Fabric"
                options={INITIAL_FABRIC}
                selected={selectedFabric}
                onChange={setSelectedFabric}
              />
              <TopFilter
                label="Style"
                options={INITIAL_STYLE}
                selected={selectedStyle}
                onChange={setSelectedStyle}
              />
              <TopFilter
                label="Color"
                options={INITIAL_COLORS.map((c) => ({ id: c.name, label: c.name }))}
                selected={selectedColors}
                onChange={setSelectedColors}
              />
              <TopFilter
                label="Zari"
                options={INITIAL_ZARI}
                selected={selectedZari}
                onChange={setSelectedZari}
              />
            </div>
          </div>
        )}



        <div className="w-full h-16  items-center justify-between px-6 rounded-lg hidden lg:flex shadow-md">
          <div className='flex'>
            <h2 className="text-2xl md:text-3xl font-bold text-black mb-2">Filter By</h2>
            <button
              onClick={clearAllFilters}
              className="flex items-center justify-center space-x-2 px-4 py-2 border-none bg-transparent text-black hover:text-gray-300 transition-all duration-800 ease-in-out transform hover:scale-105"
            >
              {/* 3-bar vertical icon */}
              <ListRestart />
            </button>

          </div>

          <div className="flex justify-between items-center w-[30%] space-x-4">
            <button
              onClick={() => setIsfilterOpen(true)}
              className="flex items-center justify-center space-x-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-red-500 transition duration-300 ease-in-out transform hover:scale-105"
            >
              {/* 3-bar vertical icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 text-black hover:text-gray-300 transition duration-300"
              >
                <defs>

                  <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stop-color="#4CAF50" />
                    <stop offset="100%" stop-color="#1B5E20" />
                  </linearGradient>
                </defs>
                <g>

                  <rect x="5" y="4" width="5" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                  <rect x="11" y="4" width="5" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                  <rect x="17" y="4" width="5" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                </g>
              </svg>
            </button>

            <button
              onClick={() => setIsfilterOpen(false)}
              className="flex items-center justify-center space-x-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-red-500 transition duration-300 ease-in-out transform hover:scale-105"
            >
              {/* 4-bar vertical icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 32 24"
                className="w-6 h-6 text-black hover:text-gray-300 transition duration-300"
              >
                <defs>

                  <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stop-color="#4CAF50" />
                    <stop offset="100%" stop-color="#1B5E20" />
                  </linearGradient>
                </defs>
                <g>

                  <rect x="3" y="4" width="4" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                  <rect x="10" y="4" width="4" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                  <rect x="17" y="4" width="4" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                  <rect x="24" y="4" width="4" height="16" rx="1.5" ry="1.5" fill="url(#barGradient)" />
                </g>
              </svg>
            </button>

          </div>

          <SortBy onSortChange={handleSortChange} />
        </div>





        <div className="flex gap-0 lg:gap-8">
          {/* Desktop Filters Sidebar */}
          {/* <div className="hidden lg:block w-72 flex-shrink-0 border-[1px]">
            <FiltersContent />
          </div> */}

          {/* Product Grid */}


          <div className=" mb-4">

          </div>


          {isFilterOpen && (

            <div className="hidden lg:block w-72 flex-shrink-0 border-[1px]">

              <FiltersContent />
            </div>


          )}


          <div className="max-w-7xl mx-auto lg:px-4 lg:py-8">
            <div
              className={`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 ${isFilterOpen ? "lg:grid-cols-3" : "lg:grid-cols-4"
                }`}
            >
              {displayedProducts.map(product => (
                <div key={product.id} className="bg-[#f5eee6] rounded-lg overflow-hidden group relative">
                  {/* Product Image Container */}
                  <div className="relative aspect-[3/4]">
                    <img
                      src={`${S3_BASE_URL + product.imageUrls[0]}`}
                      alt={product.title}
                      className="w-full h-full object-cover cursor-pointer"
                    // onClick={() => setSelectedImage(product.imageUrl)}
                    />

                    {/* Action Buttons Container - Initially hidden, shown on hover */}
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-4 opacity-0 translate-y-4 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
                      {/* Quick View Button */}
                      <button
                        className="w-10 h-10 rounded-full bg-white shadow-md flex items-center justify-center hover:scale-110 transition-transform"
                      // onClick={() => setSelectedImage(product.imageUrl)}
                      >
                        <Eye className="w-5 h-5 text-gray-600" />
                      </button>

                      {/* Wishlist Button */}
                      <button
                        className="w-10 h-10 rounded-full bg-white shadow-md flex items-center justify-center hover:scale-110 transition-transform"
                        onClick={() => setWishlist(prev => {
                          const newWishlist = new Set(prev);
                          if (newWishlist.has(product.id)) {
                            newWishlist.delete(product.id);
                          } else {
                            newWishlist.add(product.id);
                          }
                          return newWishlist;
                        })}
                      >
                        <Heart
                          className={`w-5 h-5 ${wishlist.has(product.id) ? 'text-red-500 fill-red-500' : 'text-gray-600'}`}
                        />
                      </button>

                      {/* Add to Cart Button */}
                      <button
                        className="w-10 h-10 rounded-full bg-white shadow-md flex items-center justify-center hover:scale-110 transition-transform"
                      >
                        <ShoppingBag className="w-5 h-5 text-gray-600" />
                      </button>
                    </div>

                    {/* Hover Overlay */}
                  </div>

                  {/* Product Details */}
                  <div className="p-4">
                    <h3 className="text-sm font-medium text-gray-900 mb-2 line-clamp-2">
                      {product.title}
                    </h3>
                    <p className="text-lg font-semibold">
                      ₹ {product.price.toLocaleString()}
                    </p>
                  </div>
                </div>

              ))}
            </div>

            {hasMore && (
              <div className="flex justify-center mt-8">
                <button
                  onClick={handleLoadMore}
                  className="px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200 flex items-center gap-2"
                >
                  Load More
                  <Plus className="w-4 h-4" />
                </button>
              </div>
            )}

            {/* No products found message */}
            {displayedProducts.length === 0 && (
              <div className="text-center py-12">
                <h3 className="text-lg font-medium text-gray-900">No products found</h3>
                <p className="mt-2 text-gray-500">Try adjusting your filters or search criteria</p>
              </div>
            )}
          </div>




        </div>
      </div>
      <SareeList products={displayedProducts} namelist="Sarees" />
    </div>
  );
};

export default FestiveEditShop;


// =====================================================================================================
// ======================================================================================================

const SortByModal = ({ onClose, onSortChange }) => {
  const sortOptions = [
    { id: 'new-arrivals', label: 'New Arrivals' },
    { id: 'price-low-high', label: 'Price Low To High' },
    { id: 'price-high-low', label: 'Price High To Low' },
    { id: 'most-popular', label: 'Most Popular' },
    { id: 'top-sellers', label: 'Top Sellers' },
    { id: 'recommended', label: 'Recommended' },
    { id: 'discounts', label: 'Discounts' }
  ];

  const [selectedOption, setSelectedOption] = useState('new-arrivals');

  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);
    onSortChange(optionId);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center pt-16">
      <div className="bg-white w-full max-w-sm rounded-t-lg">
        <div className="p-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium">SORT BY</h2>
            <button
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
        </div>

        <div className="py-2">
          {sortOptions.map((option) => (
            <button
              key={option.id}
              className={`w-full px-4 py-3 text-left hover:bg-gray-50 
                ${selectedOption === option.id ? 'text-pink-500' : 'text-gray-700'}`}
              onClick={() => handleOptionClick(option.id)}
            >
              <div className="flex items-center justify-between">
                <span>{option.label}</span>
                {selectedOption === option.id && (
                  <div className="w-5 h-5 rounded-full bg-pink-500 flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const CustomCheckbox = ({ checked, onChange, className = "" }) => (
  <div
    className={`w-5 h-5 border border-gray-300 rounded flex items-center justify-center transition-colors ${checked ? 'bg-black border-black' : 'bg-white'
      } ${className}`}
    onClick={onChange}
  >
    {checked && <Check className="w-3 h-3 text-white" />}
  </div>
);


const PriceRangeFilter = ({ value, onChange }) => {
  const [localRange, setLocalRange] = useState(value);

  const handleChange = (newRange) => {
    setLocalRange(newRange);
    onChange(newRange);
  };

  return (
    <div className="relative w-64">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Price Range
      </label>
      <div className="flex flex-col items-center">
        {/* Slider */}
        <div className="relative w-full">
          <input
            type="range"
            min={1200}
            max={17000}
            step={100}
            value={localRange[0]}
            onChange={(e) =>
              handleChange([parseInt(e.target.value), localRange[1]])
            }
            className="absolute w-full appearance-none bg-gray-300 h-1 rounded outline-none"
          />
          <input
            type="range"
            min={1200}
            max={17000}
            step={100}
            value={localRange[1]}
            onChange={(e) =>
              handleChange([localRange[0], parseInt(e.target.value)])
            }
            className="absolute w-full appearance-none bg-gray-300 h-1 rounded outline-none"
          />
        </div>

        {/* Track Visualization */}
        <div className="relative w-full h-1 bg-gray-200 mt-3">
          <div
            className="absolute h-full bg-blue-500 rounded"
            style={{
              left: `${((localRange[0] - 1200) / (17000 - 1200)) * 100}%`,
              right: `${100 - ((localRange[1] - 1200) / (17000 - 1200)) * 100}%`,
            }}
          ></div>
        </div>

        {/* Range Display */}
        <div className="flex justify-between w-full mt-2">
          <span className="text-sm text-gray-600">₹{localRange[0]}</span>
          <span className="text-sm text-gray-600">₹{localRange[1]}</span>
        </div>
      </div>

      {/* Reset Button */}
      {(localRange[0] !== 1200 || localRange[1] !== 17000) && (
        <button
          onClick={() => handleChange([1200, 17000])}
          className="mt-4 text-gray-500 hover:text-gray-700 text-sm"
        >
          Reset
        </button>
      )}
    </div>
  );
};

const SortBy = ({ onSortChange }) => {
  const [selectedOption, setSelectedOption] = useState("Default");

  const options = ["Default", "Price: Low to High", "Price: High to Low"];

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    onSortChange(value);
  };

  return (
    <div className="flex items-center space-x-2">
      <label htmlFor="sort-by" className="text-gray-700 inline font-medium">
        <svg className="-ms-0.5 me-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4" />
        </svg>
        Sort
      </label>
      <select
        id="sort-by"
        value={selectedOption}
        onChange={handleChange}
        className="px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};


const FilterSection = ({ title, expanded, onToggle, children }) => (
  <div className="border-b border-gray-200 py-4">
    <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={onToggle}>
      <h3 className="font-medium text-gray-900">{title}</h3>
      {expanded ? (
        <Minus className="w-4 h-4" />
      ) : (
        <Plus className="w-4 h-4" />
      )}
    </div>
    {expanded && children}
  </div>
);


const FilterSectionMD = ({ title, expanded, onToggle, children }) => {
  return (
    <div className="border-b border-gray-300 py-4">
      {/* Header Section */}
      <div
        className="flex justify-between items-center mb-2 cursor-pointer hover:bg-gray-100 p-2 rounded-lg transition-all"
        onClick={onToggle}
      >
        <h3 className="font-semibold text-lg text-gray-800">{title}</h3>
        <div
          className={`w-5 h-5 flex items-center justify-center rounded-full bg-gray-200 text-gray-600 transition-transform ${expanded ? "rotate-180" : "rotate-0"
            }`}
        >
          {expanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          )}
        </div>
      </div>
      {/* Expandable Content */}
      <motion.div
        initial={false}
        animate={{ height: expanded ? "auto" : 0 }}
        className="overflow-hidden transition-all"
      >
        <div className="p-2">{children}</div>
      </motion.div>
    </div>
  );
};


const TopFilter = ({
  label,
  options,
  selected,
  onChange,
  variant = 'dropdown' // 'dropdown' or 'button'
}) => {
  if (variant === 'button') {
    return (
      <button
        onClick={() => onChange(selected)}
        className="px-4 py-2.5 flex items-center justify-between gap-2
                   hover:bg-gray-600 transition-colors
                   text-gray-700 border-r border-gray-200 font-bold last:border-r-0"
      >
        <span className=" text-sm font-bold uppercase">{label}</span>
        {/* <ChevronDown className="w-4 h-4 text-gray-500" /> */}
      </button>
    );
  }

  return (
    <div className="flex-1 relative w-[80%] h-auto bg-gray-700">
      <div className="relative">
        {/* <div className="w-[5px] h-32 bg-gray-500"></div> */}
        <select
          value={selected}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              onChange([]);
            } else {
              onChange(prev => {
                const isArray = Array.isArray(prev);
                if (!isArray) return [value];
                return prev.includes(value)
                  ? prev.filter(item => item !== value)
                  : [...prev, value];
              });
            }
          }}
          className="w-full px-4 py-2.5 bg-white appearance-none cursor-pointer
                     text-black text-sm
                     border-t border-b border-l font-bold border-gray-100 last:border-r
                     focus:outline-none focus:ring-0
                     focus:border-gray-200"
        >
          <option value="">All {label}</option>
          {options.map((option) => {
            const value = option.id || option;
            const label = option.label || option;
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </select>

        <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
          {/* <ChevronDown className="w-4 h-4 text-gray-400" /> */}
        </div>
      </div>

      {Array.isArray(selected) && selected.length > 0 && (
        <div className="absolute mt-1 inset-x-0 flex flex-wrap gap-1.5 px-2">
          {selected.map(item => (
            <span
              key={item}
              className="inline-flex items-center px-2 py-1 rounded-full text-xs
                         bg-gray-100 text-gray-700"
            >
              {item}
              <button
                onClick={() => onChange(selected.filter(i => i !== item))}
                className="ml-1.5 hover:text-red-500"
              >
                ×
              </button>
            </span>
          ))}

        </div>
      )}

    </div>

  );
};


const ProductSkeleton = () => (
  <div className="bg-white rounded-xl shadow-sm overflow-hidden animate-pulse">
    <div className="aspect-square bg-gray-200" />
    <div className="p-4 space-y-3">
      <div className="h-4 bg-gray-200 rounded w-2/3" />
      <div className="h-6 bg-gray-200 rounded w-3/4" />
      <div className="h-4 bg-gray-200 rounded w-1/4" />
      <div className="h-10 bg-gray-200 rounded w-full mt-4" />
    </div>
  </div>
);


const TaneiraFilterSort = () => {
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState('New Arrivals');
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    category: null,
    color: null,
    fabric: null,
    style: null,
    zari: null,
    pattern: null
  });

  const sortOptions = [
    'New Arrivals',
    'Price Low To High',
    'Price High To Low',
    'Most Popular',
    'Top Sellers',
    'Recommended',
    'Discounts'
  ];

  const filterData = {
    'PRICE RANGE': [1000, 20000], // Add data if applicable
    'CATEGORY': INITIAL_CATEGORIES,
    'COLOUR': INITIAL_COLORS,
    'FABRIC': INITIAL_FABRIC,
    'STYLE': INITIAL_STYLE,
    'ZARI': INITIAL_ZARI,
    'PATTERN': INITIAL_PATTERN
  };

  const handleFilterSelection = (key, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  return (
<div className="absolute z-50 bg-purple-400 min-h-[60vh] max-h-[60vh] ">
  {/* Bottom Navigation */}
  <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
    <div className="flex">
      <button
        onClick={() => setShowSortModal(true)}
        className="flex-1 flex items-center justify-center space-x-2 py-3 border-r border-gray-200"
      >
        <ArrowDownUp size={18} />
        <span className="text-sm font-medium">SORT</span>
      </button>
      <button
        onClick={() => setShowFilterModal(true)}
        className="flex-1 flex items-center justify-center space-x-2 py-3"
      >
        <Filter size={18} />
        <span className="text-sm font-medium">FILTER</span>
      </button>
    </div>
  </div>

  {/* Sort Modal */}
  {showSortModal && (
    <div className="fixed inset-x-0 bottom-0 bg-white z-50 min-h-[60vh] max-h-[60vh] overflow-y-auto">
      <div className="p-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-medium">SORT BY</h2>
          <button onClick={() => setShowSortModal(false)} className="p-1">
            <X size={24} />
          </button>
        </div>
        <div className="space-y-4">
          {sortOptions.map((option) => (
            <button
              key={option}
              onClick={() => {
                setSelectedSort(option);
                setShowSortModal(false);
              }}
              className="flex justify-between items-center w-full py-2"
            >
              <span className="text-base text-gray-700">{option}</span>
              {selectedSort === option && (
                <div className="w-6 h-6 rounded-full bg-pink-500"></div>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  )}

  {/* Filter Modal */}
  {showFilterModal && (
    <div className="fixed inset-x-0 bottom-0 bg-white z-50 min-h-[60vh] hover- max-h-[60vh] overflow-y-auto">
      <div className="  flex flex-col bg-yellow-400 min-h-[60vh] max-h-[60vh]">
        <div className="flex  justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">FILTERS</h2>
          <button className="text-gray-500" onClick={() => setSelectedFilters({})}>
            Reset Filters
          </button>
        </div>

        <div className="flex-1 flex">
          {/* Filter Categories */}
          <div className="w-1/2 border-r bg-gray-50">
            {Object.keys(filterData).map((category) => (
              <button
                key={category}
                onClick={() => setActiveFilterCategory(category)}
                className={`w-full text-left px-4 py-3 text-sm font-medium border-b ${
                  activeFilterCategory === category ? 'bg-gray-200' : ''
                }`}
              >
                {category}
              </button>
            ))}
          </div>

          {/* Filter Options */}
          <div className="w-1/2 p-4 bg-white">
            {activeFilterCategory &&
              filterData[activeFilterCategory]?.map((item, idx) => (
                <button
                  key={idx}
                  onClick={() =>
                    handleFilterSelection(activeFilterCategory.toLowerCase(), item)
                  }
                  className="block text-left py-2"
                >
                  {item.label || item.name || item}
                </button>
              ))}
          </div>
        </div>

        <div className="p-4 border-t">
          <div className="flex space-x-4">
            <button
              onClick={() => setShowFilterModal(false)}
              className="flex-1 py-3 border rounded-full text-pink-500 font-medium"
            >
              CLOSE
            </button>
            <button
              onClick={() => setShowFilterModal(false)}
              className="flex-1 py-3 rounded-full bg-pink-500 text-white font-medium"
            >
              APPLY FILTERS
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
</div>

  );
};