import React from 'react';

const Mainlogo = ({ width }) => {
  return (
    <div>
      {/* <img src="/logok.svg" alt="Company Logo" width={width} style={{ height: 'auto' }} /> */}
      <h1 className=' text-3xl font-bold text-button-green'>KALAKSHA</h1>
    </div>
  );
}

export default Mainlogo;
