import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register } from "../../../Redux/Auth/Action";

const RegisterUserForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { auth } = useSelector((store) => store);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
    };
    dispatch(register(userData));
  };

  useEffect(() => {
    if (auth.user || auth.error) setOpenSnackBar(true);
  }, [auth]);

  return (
    <div className="min-h-screen flex items-center justify-center  relative px-4">
      <div className="max-w-md w-full space-y-8">
        {/* Registration Card */}
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          {/* Registration Header */}
          <div className="px-8 pt-8 pb-6">
            <div className="flex justify-center">
              <div className="h-12 w-12 rounded-full bg-cyan-100 flex items-center justify-center">
                <div className="h-6 w-6 rounded-full bg-cyan-400"></div>
              </div>
            </div>
            <h2 className="mt-4 text-2xl font-bold text-center text-gray-900">
              Create an Account
            </h2>
          </div>

          {/* Registration Form */}
          <div className="px-8 pb-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
                  placeholder="First Name"
                />
              </div>
              <div>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
                  placeholder="Last Name"
                />
              </div>
              <div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
                  placeholder="Email Address"
                />
              </div>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
                  placeholder="Password"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full py-3 px-4 text-white font-medium rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  style={{
                    backgroundImage: "linear-gradient(to left, #006655, #004c40)",
                  }}
                >
                  Register
                </button>
              </div>
            </form>

            <div className="mt-6 text-center">
              <span className="text-sm text-gray-500">or </span>
              <button
                onClick={() => navigate("/login")}
                className="text-sm text-gray-400 hover:text-blue-700 font-medium"
              >
                Login to Your Account
              </button>
            </div>
          </div>
        </div>

        {/* Snackbar Notification */}
        {openSnackBar && (
          <div
            className={`mt-4 text-center px-4 py-2 rounded-md ${
              auth.error ? "bg-red-500 text-white" : "bg-green-500 text-white"
            }`}
          >
            {auth.error ? auth.error : "Registration Successful!"}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterUserForm;
