import React from 'react';
import { S3_BASE_URL } from '../../../config/api';
import { useEffect, useState } from 'react';
const CartItem = ({ item  }) => (
  <div className="flex items-center p-6 border-b border-gray-100">
    <div className="relative group">
      <img 
          src={`${S3_BASE_URL}${item.product.imageUrls[0]}`}
        className="w-24 h-24 object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105" 
        alt={item.product.title}
      />
      <div className="absolute inset-0 bg-black/5 group-hover:bg-black/0 transition-colors duration-300 rounded-lg" />
    </div>
    <div className="ml-6">
      <h4 className="font-serif text-lg text-gray-800">{item.product.title}</h4>
      <div className="flex items-center mt-2">
        <span className="text-sm text-gray-600">Quantity:</span>
        <span className="ml-2 px-3 py-1 bg-gray-50 rounded-full text-sm font-medium">
          {item.quantity}
        </span>
      </div>
    </div>
  </div>
);

const CartActions = ({ onClose  ,cartq}) => (
  <div className="p-6 space-y-4">
    <div className="flex justify-center space-x-4">
      <button 
        className="w-1/2 px-6 py-3 bg-black border-2 border-gray-200 rounded-lg text-xl text-white font-medium transition-colors duration-200 "
      >
        View Cart ({cartq  || 0})
      </button>
      {/* <button 
        className="w-1/2 px-6 py-3 bg-gray-900 text-white rounded-lg font-medium transition-colors duration-200 hover:bg-gray-800"
      >
        Checkout
      </button> */}
    </div>
    <button 
      onClick={onClose}
      className="w-full text-center text-sm text-gray-500 hover:text-gray-700 transition-colors duration-200"
    >
      Continue Shopping
    </button>
  </div>
);

const SuccessCheck = () => (
  <div className="flex items-center mb-4">
    <div className="w-5 h-5 rounded-full bg-green-500 flex items-center justify-center">
      <svg className="w-3 h-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    </div>
    <span className="ml-2 text-sm font-medium text-green-500">
      Added to Cart
    </span>
  </div>
);

export const CartPopup = ({ item, onClose, cartq }) => {
  const [popcart, setPopcart] = useState(false);

  useEffect(() => {
    const popcartStatus = localStorage.getItem('popcartnew');
    if (popcartStatus === 'true') {
      setPopcart(true);
    } else {
      setPopcart(false);
    }
  }, []);

  if (!popcart) {
    return null; // Do not render the popup if popcart is false
  }

  return (
    <div className="fixed top-4 right-4 z-50 w-96 bg-white rounded-xl shadow-2xl overflow-hidden transform transition-all duration-300 ease-out">
      <div className="p-6 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <SuccessCheck />
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      <CartItem item={item} />
      <CartActions onClose={onClose} cartq={cartq} />
    </div>
  );
};
export default CartPopup;