
import axios from 'axios';
const DEPLOYED='https://app.kalaksha.com'
// const LOCALHOST='http://localhost:5454'
const LOCALHOST='http://192.168.78.205:5454'
export const API_BASE_URL = DEPLOYED

export const S3_BASE_URL = 'https://yasar-2024-25-client.s3.amazonaws.com/'

const api = axios.create({
  baseURL: API_BASE_URL,
});

const token = localStorage.getItem('jwt');
localStorage.setItem('diwalipffer',true)
api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

api.defaults.headers.post['Content-Type'] = 'application/json';

export default api;

function gettoken(){
  const token = localStorage.getItem("jwt");
  return token
}

export const addToWishlist = async (productId) =>{
  const newtoken = gettoken() 
  const url = `${API_BASE_URL}/api/wishlist/add?productId=${productId}`;
  
  try {
    const response = await fetch(url, {
      method: 'POST', // You can change it to GET or POST based on your API
      headers: {
        Authorization: `Bearer ${newtoken}`,
        "Content-Type": "application/json",
      },
    });
    
    if (response.ok) {
      const data = await response.json();
      console.log('Product added to wishlist:', data);
    } else {
      console.error('Failed to add product to wishlist');
    }
  } catch (error) {
    console.error('Error adding to wishlist:', error);
  }
}

// Example usage
addToWishlist(125);


export const addItemToCart = async (reqData) => {
  const newtoken = gettoken() 
  console.log("Request data:", reqData);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${newtoken}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${API_BASE_URL}/api/cart/add`,
      reqData,
      config
    );

    console.log("Item added to cart:", data);
    return {
      success: true,
      data,
    };
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    console.error("Error adding item to cart:", errorMessage);
    return {
      success: false,
      error: errorMessage,
    };
  }
};

export async function getCart() {
  const newtoken = gettoken() 
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${newtoken}`,
        "Content-Type": "application/json"
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/api/cart/`, config);
    console.log("cart ", data);
    return data;  // Return the cart data directly

  } catch (error) {
    console.error("Error fetching cart: ", 
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    throw error; // Throw error to be handled where the function is called
  }
}