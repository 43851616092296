import React from 'react';

const SareeList = ({ products , namelist }) => {
    console.log(products)
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex justify-between font-bold text-gray-700 mb-4">
        <div>{namelist}</div>
        <div>Price</div>
      </div>
      <div className="border-t border-gray-200">
        {products.slice(0, 20).map((product) => (
          <div 
            key={product.id} 
            className="flex justify-between py-3 border-b border-gray-200 hover:bg-gray-50"
          >
            <div className="text-gray-700 flex-1 pr-4 text-sm">
              {product.title} 
            </div>
            <div className="text-gray-700 text-sm whitespace-nowrap">₹ {product.price}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SareeList;