import React from "react";

const ReturnGuide = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-6">
      {/* Header Section */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">Return Guide</h1>
        <p className="text-lg text-gray-600">
          Our 7-day return policy for damaged or mismatched items.
        </p>
      </div>

      {/* Steps Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {[
          {
            title: "Step 1: Initiate Return via Email",
            content:
              "Send an email to returns@yourstore.com with your order ID, reason, and proof images.",
          },
          {
            title: "Step 2: Verification Process",
            content:
              "Our team will review your submission within 48 hours and notify you of the status.",
          },
          {
            title: "Step 3: Receive Replacement Details",
            content:
              "If approved, we’ll send tracking details for your replacement or further instructions.",
          },
        ].map((step, index) => (
          <div
            key={index}
            className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <h3 className="text-xl font-semibold text-blue-600">{step.title}</h3>
            <p className="mt-2 text-gray-700">{step.content}</p>
          </div>
        ))}
      </div>

      {/* Email Instructions */}
      <div className="mt-10 bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-gray-800">Email Template:</h2>
        <p className="mt-2 text-gray-600">
          Use this format when sending your return request:
        </p>
        <pre className="bg-gray-100 p-4 rounded-lg mt-4 text-gray-700 overflow-auto">
          Subject: Return Request - Order #XXXX
          <br />
          Order ID: [Your Order ID]
          <br />
          Issue: [Damaged/Color Mismatch]
          <br />
          Details: [Brief description]
          <br />
          Attach: [Clear images of the item showing the issue]
        </pre>
      </div>
    </div>
  );
};

export default ReturnGuide;
