import React, { useState } from 'react';
import {
  Mail,
  Phone,
  MapPin,
  Facebook,
  Twitter,
  Instagram,
  ChevronRight,
} from 'lucide-react';

const footerData = {
  companyInfo: {
    name: "KALAKSHA",
    description: "Crafting Kalaksha experiences since 1990. Excellence in every detail.",
    socialLinks: [
      { icon: "Facebook", url: "https://facebook.com/luxebrand", label: "Facebook" },
      { icon: "Twitter", url: "https://twitter.com/luxebrand", label: "Twitter" },
      { icon: "Instagram", url: "https://instagram.com/luxebrand", label: "Instagram" },
    ],
  },
  quickLinks: [
    { name: "About Us", url: "#" },
    { name: "Collections", url: "#" },
    { name: "Sustainability", url: "#" },
  ],
  customerService: [
    { name: "Contact Us", url: "/contact-us" },
    { name: "Returns", url: "/return" },
  ],
  usefulLinks: [
    { name: "Track my order", url: "/contact-us" },
    { name: "Pincode check", url: "/return" },
  ],
  newsletter: {
    description: "Subscribe to receive updates, access to exclusive deals, and more.",
  },
  contactInfo: [
    { icon: "Phone", text: "+91 9480021547" },
    { icon: "Mail", text: "support@kalaksha.com" },
  ],
  bottomLinks: [
    { name: "Privacy Policy", url: "/privacy-policy" },
    { name: "Terms of Service", url: "/terms-condition" },
    { name: "Refund & Cancellation", url: "/refund-cancellation" },
    { name: "Shipping Policy", url: "/shipping-policy" },
  ],
};

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTimeout(() => setIsSubmitting(false), 2000); // Simulate submission
  };

  return (
    <footer className="bg-[#006655] text-[#F9F6EE]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        {/* Main Content */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-12">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold Head tracking-wide">{footerData.companyInfo.name}</h3>
            <p className="text-sm Para leading-relaxed opacity-90">
              {footerData.companyInfo.description}
            </p>
            <div className="flex space-x-4">
              {footerData.companyInfo.socialLinks.map((link) => (
                <a
                  key={link.icon}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Visit our ${link.label}`}
                  className="group"
                >
                  {link.icon === "Facebook" && (
                    <Facebook className="w-5 h-5 text-opacity-80 group-hover:text-opacity-100 transition-opacity" />
                  )}
                  {link.icon === "Twitter" && (
                    <Twitter className="w-5 h-5 text-opacity-80 group-hover:text-opacity-100 transition-opacity" />
                  )}
                  {link.icon === "Instagram" && (
                    <Instagram className="w-5 h-5 text-opacity-80 group-hover:text-opacity-100 transition-opacity" />
                  )}
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold tracking-wide Head">Quick Links</h3>
            <ul className="mt-4 SubHead space-y-2">
              {footerData.quickLinks.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.url}
                    className="flex items-center text-sm opacity-80 hover:opacity-100 transition-opacity group"
                  >
                    <ChevronRight className="w-4 h-4 mr-2 group-hover:text-opacity-100 transition-opacity" />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Customer Service */}
          <div>
            <h3 className="text-lg font-semibold Head tracking-wide">Customer Service</h3>
            <ul className="mt-4 SubHead space-y-2">
              {footerData.customerService.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.url}
                    className="flex items-center text-sm opacity-80 hover:opacity-100 transition-opacity group"
                  >
                    <ChevronRight className="w-4 h-4 mr-2 group-hover:text-opacity-100 transition-opacity" />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>


          <div> 
            <h3 className="text-lg font-semibold Head tracking-wide">Useful Links</h3>
            <ul className="mt-4 SubHead space-y-2">
              {footerData.usefulLinks.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.url}
                    className="flex items-center text-sm opacity-80 hover:opacity-100 transition-opacity group"
                  >
                    <ChevronRight className="w-4 h-4 mr-2 group-hover:text-opacity-100 transition-opacity" />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Contact Info */}
        <div className="mt-12 border-t border-opacity-20 pt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {footerData.contactInfo.map((info) => (
            <div key={info.icon} className="flex items-center space-x-3">
              {info.icon === "Phone" && <Phone className="w-5 h-5" />}
              {info.icon === "Mail" && <Mail className="w-5 h-5" />}
              <span className="SubHead text-sm">{info.text}</span>
            </div>
          ))}
        </div>

        {/* Bottom Links */}
        <div className="mt-12 border-t SubHead border-opacity-20 pt-8 text-center text-sm opacity-90">
          <p>&copy; {currentYear} {footerData.companyInfo.name}. All rights reserved.</p>
          <div className="mt-4 space-x-4">
            {footerData.bottomLinks.map((link) => (
              <a key={link.name} href={link.url} className="hover:opacity-100 SubHead transition-opacity">
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
