import React from 'react';
import { Search, ShoppingBag, Menu, ChevronRight } from 'lucide-react';
import slide4 from '../../../Sliderimg/4slide.png'
import Cat1 from '../../../asstes/Category/Kanchipurm tissue silk.jpg'
import Cat2 from '../../../asstes/Category/Kanchipuram silk.jpg'
import Cat3 from '../../../asstes/Category/Soft silk.jpg'
import Cat4 from '../../../asstes/Category/Arani silk.jpg'
import Cat5 from '../../../asstes/Category/Mysore silk.jpg'

import Price1 from '../../../asstes/PriceBy/under4k.jpg'
import Price2 from '../../../asstes/PriceBy/4t10k.jpg'
import Price3 from '../../../asstes/PriceBy/10t20k.jpg'
import Price4 from '../../../asstes/PriceBy/above20k.jpg'

import price from '../../../Sliderimg/price.jpg'
import { motion } from 'framer-motion';
import type1 from '../../../Sliderimg/sim.png'
import type2 from '../../../Sliderimg/grd.png'
import Ourpromises from '../../promises/Ourpromises';

const HomeCat = () => {
  const navItems = ['DIAMOND', 'GOLD', 'GEMSTONE', 'UNCUT DIAMOND', 'PLATINUM', 'GOLD COINS', 'SILVER', 'WATCHES', 'GIFTS', 'JEWELLERY', 'GIFT CARDS', 'GOLD RATE'];
  
  const categories = [
    { title: 'Kanchipurm tissue silk', image: Cat1 },
    { title: 'Kanchipuram silk', image: Cat2 },
    { title: 'Soft sil', image: Cat3 },
    { title: 'Arani silk', image: Cat4 },
    { title: 'Mysore silk', image: Cat5 },
  ];
  const prices = [
    { title: 'Under ₹4k', image: Price1, price: 'Under ₹4k' },
    { title: '₹4k - 10k', image: Price2, price: '₹4k - 10k' },
    { title: '₹10k - 20k', image: Price3, price: '₹10k - 20k' },
    { title: 'Above ₹20k', image: Price4, price: 'Above ₹20k' },
  ];

  const diwaliCollection = [
    { title: 'Statement Necklaces', price: '30,400', image: 'https://localnation.co.in/cdn/shop/products/t729hRxZQS.jpg?v=1695819518' },
    { title: 'Radiant Pendant Sets', price: '30,400', image: 'https://static.wixstatic.com/media/faf1ba_bc70dc9fa2ae4d05b264d966500fad7f~mv2.jpg/v1/fill/w_480,h_240,al_c,lg_1,q_80,enc_auto/faf1ba_bc70dc9fa2ae4d05b264d966500fad7f~mv2.jpg' },
    { title: 'Stunning Earrings', price: '10,244', image: 'https://www.parijatstore.com/cdn/shop/files/DSC05524_Custom.jpg?v=1717152803' },
    { title: 'Sleek Rings', price: '10,100', image: 'https://t3.ftcdn.net/jpg/09/05/87/90/360_F_905879026_6aZ0Cf3tSurY5Kq6mKIe8dwSpljBw3uw.jpgs' },
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      {/* <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <Menu className="h-6 w-6 mr-4" />
              <span className="text-xl font-semibold">Banner</span>
            </div>
            <div className="flex items-center space-x-4">
              <Search className="h-6 w-6" />
              <ShoppingBag className="h-6 w-6" />
            </div>
          </div>
        </div> */}
        {/* Navigation Items */}
        {/* <div className="overflow-x-auto">
          <div className="flex space-x-6 px-4 py-2 text-sm whitespace-nowrap">
            {navItems.map((item) => (
              <a key={item} href="#" className="text-gray-600 hover:text-gray-900">
                {item}
              </a>
            ))}
          </div>
        </div>
      </nav> */}

      {/* Pick Your Look Section */}
      {/* <section className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-2xl font-serif text-center mb-2">Pick Your Look</h2>
        <p className="text-center text-gray-600 mb-6">Embrace the golden look of the style</p>
        <div className="grid md:grid-cols-2 gap-6">
          <div 
            style={{
              backgroundImage: `url(${type1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
           className="bg-button-green rounded-lg p-20 text-center">
            <h3 className="text-xl text-Floral-white mb-2">SIMPLE</h3>
          </div>
          <div
            style={{
            backgroundImage: `url(${type2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            }}
           className="bg-button-green rounded-lg p-20  text-center">
            <h3 className="text-xl text-Floral-white mb-2">GRAND</h3>
          </div>
        </div>
      </section> */}

<div className=' w-full h-auto flex justify-center items-center bg-gradient-to-r from-[#EBDBFF] to-[#f8f5ed] '>
<section className="max-w-6xl mx-auto   px-4 py-8">
        <h2 className="text-[32px]  leading-[40px] font-[400] Head mt-8  text-center  mb-4">Shop By Category</h2>
        <p className="text-center SubHead text-gray-600 mb-6">Explore the since saree to make moments</p>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          {categories.map((category) => (
            <motion.div 
  key={category.title} 
  className="relative w-[80%] mb-3 hov rounded-lg overflow-hidden group rounded-tl-[50px]  rounded-br-[50px] rounded-tr-lg rounded-bl-lg"
  whileHover={{ scale: 1.05 }}  // Scale the whole section on hover
  transition={{ duration: 0.3 }}  // Smooth transition for the scale effect
>
  <img 
    src={category.image} 
    alt={category.title} 
    className="w-full aspect-[3/4] object-cover"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  />

  <motion.div 
    className="absolute flex justify-center items-end h-[100%] bottom-0 left-0 right-0 bg-gradient-to-b from-slate-100/0 to-purple-950"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  ></motion.div>

  <motion.div 
    className="absolute text-center uppercase bottom-0 w-full left-0 bg-gradient-to-r from-purple-800 to-purple-400 text-white text-xs lg:text-sm font-semibold px-8 py-3 rounded-r-md"
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
  >
    {category.title}
  </motion.div>
</motion.div>
          ))}
        </div>
      </section>
</div>


<div className=' flex justify-center items-center bg-gradient-to-r from-[#f2ebda] to-[#f8f5ed]'> 
<section className="max-w-7xl mx-auto py-12">
  <h2 className="text-[32px]  leading-[40px] font-[400] Head text-center mb-4">Shop by Price</h2>
  <p className="text-center SubHead text-gray-600 mb-8">
    Explore stylish finds within your financial comfort zone without compromise.
  </p>
  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
    {prices.map((category) => (
   <motion.div
   key={category.title}
   className="shadow-lg overflow-hidden rounded-tl-[50px]  rounded-br-[50px] rounded-tr-lg rounded-bl-lg "
   initial={{ opacity: 0, scale: 0.9 }}
   animate={{ opacity: 1, scale: 1 }}
   transition={{ duration: 0.5, ease: "easeOut" }}
 >
   <div className="relative bg-white overflow-hidden aspect-[1/1]">
     <motion.img
       src={category.image}
       alt={category.title}
       className="w-full h-auto object-cover"
       whileHover={{ scale: 1.05 }}
       transition={{ duration: 0.3 }}
     />

       <motion.div 
    className="absolute flex justify-center items-end h-[80%] bottom-0 left-0 right-0 bg-gradient-to-b from-slate-100/0 to-yellow-700"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  ></motion.div>
     {/* <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-black bg-opacity-50 text-white text-center">
       {category.title}
     </div> */}
   </div>
   <div className=" bg-gradient-to-r from-yellow-600 to-yellow-400  text-center py-2">
     <motion.p
       className=" text-white text-lg uppercase font-medium"
       whileHover={{  scale: 1.1 }}
     >
       {category.price}
     </motion.p>
   </div>
 </motion.div>
    ))}
  </div>
</section>
</div>



<Ourpromises/>


      {/* Diwali Collection */}
      {/* <section className="max-w-7xl mx-auto px-4 py-8 bg-purple-50">
        <h2 className="text-2xl font-serif text-center mb-2">Diwali Diamond Jewellery</h2>
        <p className="text-center text-gray-600 mb-6">Sparkle through the festive nights with timeless diamonds</p>
        <div className="grid md:grid-cols-2 gap-6">
          <div className="relative rounded-lg overflow-hidden">
            <img src={diwaliCollection[0].image} alt="Statement Necklaces" className="w-full h-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/60">
              <h3 className="text-white text-2xl font-medium">{diwaliCollection[0].title}</h3>
            </div>
          </div>
          <div className="grid gap-6">
            {diwaliCollection.slice(1).map((item) => (
              <div key={item.title} className="relative rounded-lg overflow-hidden">
                <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60">
                  <h3 className="text-white text-lg font-medium">{item.title}</h3>
                  <p className="text-white/90">Starting from ₹{item.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default HomeCat;