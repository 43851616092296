import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../../../Redux/Customers/Cart/Action';
import CartItem from './CartItem';
import OurPromises from '../../promises/Ourpromises';
import HomeProducts from '../Home/HomeProducts';

// Custom Button Component
const Button = ({ className = "", variant = "default", children, ...props }) => {
  const baseStyles = "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none";
  const variantStyles = {
    default: "bg-black text-white hover:bg-gray-800",
    outline: "border border-gray-200 hover:bg-gray-100",
    ghost: "hover:bg-gray-100",
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

// Custom Input Component
const Input = React.forwardRef(({ className = "", ...props }, ref) => {
  return (
    <input
      className={`flex h-10 w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      {...props}
    />
  );
});

// Custom Card Components
const Card = ({ className = "", children }) => {
  return (
    <div className={`rounded-lg border border-gray-200 bg-white text-gray-950 shadow-sm ${className}`}>
      {children}
    </div>
  );
};

const CardHeader = ({ className = "", children }) => {
  return (
    <div className={`flex flex-col space-y-1.5 p-6 ${className}`}>
      {children}
    </div>
  );
};

const CardTitle = ({ className = "", children }) => {
  return (
    <h3 className={`text-2xl font-semibold leading-none tracking-tight ${className}`}>
      {children}
    </h3>
  );
};

const CardContent = ({ className = "", children }) => {
  return (
    <div className={`p-6 pt-0 ${className}`}>
      {children}
    </div>
  );
};

// Custom Alert Component
const Alert = ({ className = "", variant = "default", children }) => {
  const baseStyles = "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-gray-950";
  const variantStyles = {
    default: "bg-white text-gray-950 border-gray-200 [&>svg]:text-gray-950",
    destructive: "border-red-200 bg-red-50 text-red-700 [&>svg]:text-red-700",
    success: "border-green-200 bg-green-50 text-green-700 [&>svg]:text-green-700",
  };

  return (
    <div
      role="alert"
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
    >
      {children}
    </div>
  );
};

// Custom Separator Component
const Separator = ({ className = "", orientation = "horizontal" }) => {
  return (
    <div
      className={`shrink-0 bg-gray-200 ${
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]"
      } ${className}`}
    />
  );
};

// Custom Icon Components
const ShoppingBagIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
    <line x1="3" y1="6" x2="21" y2="6" />
    <path d="M16 10a4 4 0 0 1-8 0" />
  </svg>
);

const AlertCircleIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="12" />
    <line x1="12" y1="16" x2="12.01" y2="16" />
  </svg>
);

const CheckCircleIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <polyline points="22 4 12 14.01 9 11.01" />
  </svg>
);

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = localStorage.getItem('jwt');
  const { cart } = useSelector(store => store);
  const [couponCode, setCouponCode] = React.useState('');
  const [couponError, setCouponError] = React.useState('');
  const [couponSuccess, setCouponSuccess] = React.useState('');
  const [discount, setDiscount] = React.useState(0);

  useEffect(() => {
    dispatch(getCart(jwt));
  }, [jwt, dispatch]);

  const applyCoupon = async () => {
    try {
      const response = await fetch('https://app.kalaksha.com/api/orders/apply', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({ code: couponCode })
      });
      const data = await response.json();
      
      if (!response.ok) {
        setCouponError(data.message || 'Invalid coupon code');
        setCouponSuccess('');
        setDiscount(0);
        return;
      }

      setCouponSuccess(`Valid Coupon Code. Discount: ${data.Discount}%`);
      setDiscount(data.Discount);
      localStorage.setItem('appliedCouponCode', couponCode);
      localStorage.setItem(couponCode, data.Discount);
      dispatch(getCart(jwt));
      setCouponError('');
    } catch (error) {
      setCouponError('Failed to apply coupon');
      setCouponSuccess('');
      setDiscount(0);
    }
  };

  // Ensure cartItems exists and is an array before mapping
  const cartItems = cart.cartItems || [];

  if (!cartItems.length) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-6">
        <div className="bg-gray-100 p-6 rounded-full">
          <ShoppingBagIcon className="w-12 h-12 text-gray-400" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-700">Your cart is empty</h2>
        <Button
          onClick={() => navigate('/products')}
          className="px-6 py-3"
        >
          Continue Shopping
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
      <div className="grid lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-4">
          {cartItems.map((item) => (
            item && item.id && (
              <CartItem key={item.id} item={item} showButton={true} />
            )
          ))}
        </div>

        <div className="lg:col-span-1">
          <Card className="sticky top-4">
            <CardHeader>
              <CardTitle>Order Summary</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-4">
                <div className="flex space-x-2">
                  <Input
                    placeholder="Enter coupon code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    className="flex-1"
                  />
                  <Button
                    onClick={applyCoupon}
                    disabled={!couponCode}
                    className="px-4"
                  >
                    Apply
                  </Button>
                </div>

                {couponError && (
                  <Alert variant="destructive">
                    <AlertCircleIcon className="h-4 w-4" />
                    <div className="ml-6">{couponError}</div>
                  </Alert>
                )}

                {couponSuccess && (
                  <Alert variant="success">
                    <CheckCircleIcon className="h-4 w-4" />
                    <div className="ml-6">{couponSuccess}</div>
                  </Alert>
                )}
              </div>

              <Separator className="my-4" />

              <div className="space-y-3">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Price ({cart.cart?.totalItem} items)</span>
                  <span className="font-medium">₹{cart.cart?.totalPrice}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Discount</span>
                  <span className="text-green-600 font-medium">
                    -₹{((cart.cart?.totalPrice || 0) * discount / 100).toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Delivery</span>
                  <span className="text-green-600 font-medium">Free</span>
                </div>
              </div>

              <Separator className="my-4" />

              <div className="flex justify-between items-center">
                <span className="text-lg font-semibold">Total Amount</span>
                <span className="text-xl font-bold">
                  ₹{(cart.cart?.totalPrice - (cart.cart?.totalPrice * discount / 100)).toFixed(2)}
                </span>
              </div>

              <Button
                onClick={() => navigate('/checkout?step=2')}
                className="w-full py-6"
              >
                Proceed to Checkout
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
      <OurPromises />
      <HomeProducts title="Trending now" subtitle=" " btext="Continue Shopping" />
    </div>
  );
};

export default Cart;
