import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

const AnnouncementBar = () => {
  // Get the current route using useLocation
  const location = useLocation();

  // Define your messages with more context
  const messages = [
    {
      text: "🎉 Enjoy 40% OFF on your first purchase!",
      link: "/offers",
      buttonText: "Shop Now"
    },
    {
      text: "👗 Discover our latest saree collection!",
      link: "/collections/sarees",
      buttonText: "Explore"
    },
    {
      text: "🌟 Limited-time offer: Buy 1 Get 1 on selected items!",
      link: "/promotions",
      buttonText: "View Offer"
    },
    {
      text: "🚚 Free shipping on orders above ₹999!",
      link: "/shipping-info",
      buttonText: "Learn More"
    }
  ];

  // State to manage the current message index and visibility
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  // Auto-slide effect with pause on hover
  useEffect(() => {
    let intervalId;
    
    // Only start interval if announcement bar is visible
    if (isVisible) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 5000); // Change slide every 5 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [messages.length, isVisible]);

  // Manual navigation handlers
  const handlePrevMessage = useCallback(() => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? messages.length - 1 : prevIndex - 1
    );
  }, [messages.length]);

  const handleNextMessage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
  }, [messages.length]);

  // Handle message click (if link is provided)
  const handleMessageClick = (link) => {
    if (link) {
      window.location.href = link;
    }
  };

  // Only render the component if on the root route
  if (location.pathname !== '/') {
    return null;
  }

  // If announcement bar is not visible, return null
  if (!isVisible) {
    return null;
  }

  return (
    // bg-gradient-to-r from-purple-800 to-purple-400
    <div 
      className="relative w-full overflow-hidden text-sm font-medium text-white sm:px-10 lg:px-12 bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-500"
    >
      {/* Close button */}
      <button 
        onClick={() => setIsVisible(false)}
        className="absolute right-2 top-1/2 -translate-y-1/2 z-10 text-white hover:text-gray-200"
        aria-label="Close announcement"
      >
        <X size={20} />
      </button>

      {/* Navigation arrows */}
      <button 
        onClick={handlePrevMessage}
        className="absolute left-2 top-1/2 -translate-y-1/2 z-10 text-white hover:text-gray-200"
        aria-label="Previous announcement"
      >
        <ChevronLeft size={20} />
      </button>
      <button 
        onClick={handleNextMessage}
        className="absolute right-10 top-1/2 -translate-y-1/2 z-10 text-white hover:text-gray-200"
        aria-label="Next announcement"
      >
        <ChevronRight size={20} />
      </button>

      {/* Announcement Slider */}
      <div 
        className="relative w-full h-10 flex items-center justify-center overflow-hidden"
      >
        <div 
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {messages.map((message, index) => (
            <div 
              key={index} 
              className="min-w-full h-10 flex items-center justify-center relative"
              onClick={() => handleMessageClick(message.link)}
            >
              <div className="flex items-center space-x-2">
                <p className="text-center text-[10px] lg:text-base font-bold">
                  {message.text}
                </p>
                {message.link && message.buttonText && (
                  <span className="text-[8px] lg:text-xs underline cursor-pointer">
                    {message.buttonText}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Dot indicators */}
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 flex space-x-1 pb-1">
        {messages.map((_, index) => (
          <span
            key={index}
            className={`h-1 w-1 rounded-full ${
              index === currentIndex ? 'bg-white' : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default AnnouncementBar;