import { useState } from "react";
import { Mail, Phone, MessageCircle, MapPin } from "lucide-react";
import { motion } from "framer-motion";

const ContactOption = ({ icon: Icon, title, value, href }) => (
  <motion.div
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
    className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl"
  >
    <div className="flex flex-col items-center text-center">
      <Icon className="w-10 h-10 mb-4 text-[#006655]" />
      <h3 className="text-lg font-bold mb-2 text-gray-800">{title}</h3>
      {href ? (
        <a
          href={href}
          className="text-[#006655] font-medium hover:underline"
        >
          {value}
        </a>
      ) : (
        <p className="text-gray-600">{value}</p>
      )}
    </div>
  </motion.div>
);

const AccordionItem = ({ title, children, isOpen, onClick }) => (
  <div className="mb-4 border-b">
    <button
      onClick={onClick}
      className="w-full text-left py-3 text-lg font-semibold text-[#006655] transition hover:bg-gray-100"
    >
      {title}
    </button>
    {isOpen && <div className="p-4 text-gray-700 bg-gray-50">{children}</div>}
  </div>
);

export default function ContactUs() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12 px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-5xl font-extrabold text-[#006655] mb-4">
            Contact Our Support Team
          </h1>
          <p className="text-xl text-gray-600">
            Available from 9AM-6PM IST, Monday to Saturday.
          </p>
        </motion.div>

        {/* Contact Options */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <ContactOption
            icon={Mail}
            title="Email"
            value="support@kalaksha.com"
            href="mailto:support@kalaksha.com"
          />
          <ContactOption
            icon={Phone}
            title="Phone"
            value="+91-97422 12377"
            href="tel:+919742212377"
          />
          <ContactOption
            icon={MessageCircle}
            title="WhatsApp"
            value="+91-97422 12377"
            href="https://wa.me/919742212377"
          />
        </div>

        {/* Address */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mb-12 bg-white rounded-lg shadow-lg p-6"
        >
          <div className="flex items-center mb-4">
            <MapPin className="w-8 h-8 text-[#006655] mr-3" />
            <h3 className="text-2xl font-semibold text-gray-800">Address</h3>
          </div>
          <p className="text-gray-600">
            No 74, 8 Cross near Anjiniar, Ittamadu, BSK 3 Stage, Karnataka,
            Bangalore - 560085
          </p>
        </motion.div>

        {/* FAQ Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white rounded-lg shadow-lg p-6"
        >
          <h2 className="text-3xl font-bold text-[#006655] mb-6">
            Frequently Asked Questions
          </h2>
          <AccordionItem
            title="How do I request a replacement?"
            isOpen={activeAccordion === "item-1"}
            onClick={() => toggleAccordion("item-1")}
          >
            <ol className="list-decimal list-inside space-y-2 text-gray-700">
              <li>
                Contact our customer support via email, phone, or WhatsApp using
                the details above.
              </li>
              <li>
                Provide your order number and describe the issue with the
                product.
              </li>
              <li>
                Include photographic evidence of the damage (if applicable).
              </li>
              <li>
                Our team will review your request and get back to you with the
                next steps.
              </li>
            </ol>
            <p className="mt-4 text-sm text-gray-600">
              Important: Notify us within 7 days of receiving your order for
              replacement eligibility.
            </p>
          </AccordionItem>
          <AccordionItem
            title="What are your business hours?"
            isOpen={activeAccordion === "item-2"}
            onClick={() => toggleAccordion("item-2")}
          >
            Our business hours are 9AM to 6PM IST, Monday through Saturday. We
            are closed on Sundays and public holidays.
          </AccordionItem>
          <AccordionItem
            title="How long does it take to process a refund?"
            isOpen={activeAccordion === "item-3"}
            onClick={() => toggleAccordion("item-3")}
          >
            Refunds typically take 5-10 business days after approval.
          </AccordionItem>
        </motion.div>
      </div>
    </div>
  );
}
