import React, { useState, useEffect } from 'react';
import heroSlider1 from '../../../Sliderimg/heroSlider1.png'
import heroSlider2 from '../../../Sliderimg/heroSlider2.png'
import heroSlider3 from '../../../Sliderimg/heroSlider3.png'
import heroSlider4 from '../../../Sliderimg/heroSlider4.png'
import heroSlider5 from '../../../Sliderimg/heroSlider5.png'
import TopGap from '../Navbar/TopGap';

const HeroSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const slides = [
    {
      url: heroSlider1,
      alt: "Slide 1"
    },
    {
        url: heroSlider2,
        alt: "Slide 2"
      },
      {
        url: heroSlider3,
        alt: "Slide 3"
      },
      {
        url: heroSlider4,
        alt: "Slide 4"
      },

  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full h-[auto] overflow-hidden  shadow-lg">
      {/* Stage Wrap */}
      <TopGap/>
      <div className="absolute inset-0 bg-gradient-to-r from-gray-100 to-gray-200 z-10" />
      
      {/* Slider Container */}
      <div className="relative z-10">
        <div 
          className="flex transition-transform duration-500 ease-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0"
            >
              <img
                src={slide.url}
                alt={slide.alt}
                className="w-full h-[auto] object-cover"
              />
            </div>
          ))}
        </div>

        {/* Dots Navigation */}
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-3 h-3 rounded-full transition-all ${
                index === currentIndex ? 'bg-white scale-110' : 'bg-white bg-opacity-50'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;