import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderById } from "../../../Redux/Customers/Order/Action";

const BaseCard = ({ children, className = "" }) => (
  <div className={`bg-white rounded-xl shadow-md p-6 ${className}`}>
    {children}
  </div>
);

const OrderButton = ({ status, onClick }) => {
  const buttonText = status === "DELIVERED" ? "Return Order" : "Cancel Order";
  const buttonColor = status === "DELIVERED" ? "bg-red-50 text-red-600 hover:bg-red-100" : "bg-purple-50 text-purple-600 hover:bg-purple-100";
  
  return (
    <button 
      onClick={onClick}
      className={`${buttonColor} px-6 py-2 rounded-full font-medium transition-all duration-200 hover:shadow-md`}
    >
      {buttonText}
    </button>
  );
};

const OrderItem = ({ item, onRateClick }) => (
  <div className="group hover:-translate-y-1 transition-all duration-300">
    <BaseCard className="overflow-hidden">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="relative h-24 w-24 rounded-lg overflow-hidden flex-shrink-0">
          <img
            className="object-cover object-top w-full h-full"
            src={item?.product.imageUrl}
            alt={item.product.title}
          />
        </div>
        
        <div className="flex-grow space-y-3">
          <h3 className="font-semibold text-lg text-gray-800">
            {item.product.title}
          </h3>
          <div className="space-y-1 text-sm text-gray-500">
            <p>Color: Pink • Size: {item.size}</p>
            <p>Seller: {item.product.brand}</p>
          </div>
          <p className="text-lg font-bold text-green-600">
            ₹{item.price}
          </p>
        </div>

        <button
          onClick={() => onRateClick(item.product.id)}
          className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-50 text-gray-700 hover:bg-purple-50 hover:text-purple-600 transition-colors duration-200"
        >
          <svg 
            className="w-4 h-4" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
          </svg>
          Rate & Review
        </button>
      </div>
    </BaseCard>
  </div>
);

const OrderStatus = ({ status }) => {
  const steps = ["PLACED", "CONFIRMED", "SHIPPED", "DELIVERED"];
  const currentStep = steps.indexOf(status);
  
  return (
    <div className="flex items-center w-full py-8">
      {steps.map((step, index) => (
        <div key={step} className="flex-1 relative">
          <div className="flex items-center">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center font-medium ${
              index <= currentStep 
                ? 'bg-purple-600 text-white' 
                : 'bg-gray-100 text-gray-400'
            }`}>
              {index + 1}
            </div>
            {index < steps.length - 1 && (
              <div className={`h-1 flex-1 ${
                index < currentStep ? 'bg-purple-600' : 'bg-gray-100'
              }`} />
            )}
          </div>
          <span className="absolute -bottom-6 left-0 text-xs text-gray-600 font-medium whitespace-nowrap">
            {step.charAt(0) + step.slice(1).toLowerCase()}
          </span>
        </div>
      ))}
    </div>
  );
};

const SectionTitle = ({ children }) => (
  <h2 className="text-xl font-semibold text-gray-800 mb-4">{children}</h2>
);

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { order } = useSelector((store) => store);

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, [dispatch, orderId]);

  const { orderStatus, orderItems, shippingAddress } = order.order || {};

  const handleNavigateToReview = (productId) => {
    navigate(`/account/rate/${productId}`);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 space-y-6">
      <BaseCard>
        <SectionTitle>Delivery Address</SectionTitle>
        <div className="bg-gray-50 p-4 rounded-lg">
          {shippingAddress && (
            <div className="space-y-2">
              <p className="font-medium text-gray-800">{shippingAddress.name}</p>
              <p className="text-gray-600">
                {shippingAddress.streetAddress}, {shippingAddress.city}
              </p>
              <p className="text-gray-600">
                {shippingAddress.state}, {shippingAddress.pinCode}
              </p>
              <p className="text-gray-600">
                Phone: {shippingAddress.mobile}
              </p>
            </div>
          )}
        </div>
      </BaseCard>

      <BaseCard>
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
          <SectionTitle>Order Status</SectionTitle>
          {/* <OrderButton status={orderStatus} onClick={() => {}} /> */}
        </div>
        <OrderStatus status={2} />
      </BaseCard>

      <BaseCard>
        <SectionTitle>Order Items</SectionTitle>
        <div className="space-y-4">
          {orderItems?.map((item) => (
            <OrderItem 
              key={item.product.id} 
              item={item} 
              onRateClick={handleNavigateToReview} 
            />
          ))}
        </div>
      </BaseCard>
    </div>
  );
};

export default OrderDetails;