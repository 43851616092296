import React, { useEffect, useState } from 'react';
import { Check, Package, Calendar, MapPin, ChevronRight, Filter, Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, S3_BASE_URL } from '../../../config/api';

const OrderPage = () => {
  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const orderStatus = [
    { label: "On The Way", value: "onTheWay", color: "amber" },
    { label: "Delivered", value: "delivered", color: "green" },
    { label: "Cancelled", value: "cancelled", color: "red" },
    { label: "Returned", value: "returned", color: "purple" },
  ];

  const handleTrackOrder = (id) => {
    navigate(`/account/order/${id}`);
  };

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/orders/user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setOrders(data);
        } else {
          console.error('Failed to fetch orders');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const StatusBadge = ({ status }) => {
    const statusConfig = {
      delivered: { bg: "bg-green-50", text: "text-green-700", ring: "ring-green-600/20" },
      onTheWay: { bg: "bg-amber-50", text: "text-amber-700", ring: "ring-amber-600/20" },
      cancelled: { bg: "bg-red-50", text: "text-red-700", ring: "ring-red-600/20" },
      returned: { bg: "bg-purple-50", text: "text-purple-700", ring: "ring-purple-600/20" }
    };

    const config = statusConfig[status] || statusConfig.onTheWay;

    return (
      <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${config.bg} ${config.text} ring-1 ring-inset ${config.ring}`}>
        {status}
      </span>
    );
  };

  const OrderCard = ({ order }) => {
    const isSelected = selectedOrders.has(order.id);

    const toggleSelection = () => {
      const newSelected = new Set(selectedOrders);
      if (isSelected) {
        newSelected.delete(order.id);
      } else {
        newSelected.add(order.id);
      }
      setSelectedOrders(newSelected);
    };

    return (
      <div className={`group relative bg-white rounded-2xl transition-all duration-300 hover:shadow-xl border ${isSelected ? 'ring-2 ring-violet-200 border-violet-200 bg-violet-50/30' : 'border-gray-100'}`}>
        <div className="p-6">
          <div key={order.id} className="flex flex-col md:flex-row gap-6">
            <div className="relative w-full md:w-48 h-48">
              <div className="absolute inset-0 bg-gradient-to-tr from-violet-500/10 via-transparent to-transparent rounded-xl" />
              <img
                src={`${S3_BASE_URL}${order.orderItems[0].product.imageUrls[0]}`}
                alt="Product"
                className="w-full h-full object-cover rounded-xl"
              />
            </div>

            <div className="flex-1 space-y-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900">
                    {order?.orderItems[0]?.product?.title || 'Designer Silk Saree'}
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">Order #{order?.id}</p>
                </div>
                <StatusBadge status={order?.orderStatus || 'processing'} />
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-violet-50 rounded-lg">
                    <Package size={18} className="text-violet-600" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">Quantity</p>
                    <p className="text-sm text-gray-500">{order?.orderItems[0]?.quantity || 1}</p>
                  </div>
                </div>

                {/* <div className="flex items-center gap-3">
                  <div className="p-2 bg-violet-50 rounded-lg">
                    <Calendar size={18} className="text-violet-600" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">Delivery</p>
                    <p className="text-sm text-gray-500">7 days</p>
                  </div>
                </div> */}

                <div className="flex items-center gap-3">
                  <div className="p-2 bg-violet-50 rounded-lg">
                    <MapPin size={18} className="text-violet-600" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">Location</p>
                    <p className="text-sm text-gray-500">{order?.user?.addresses[0]?.city || 'City'}</p>
                  </div>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-100">
                <div className="flex justify-between items-center">
                  <div className="flex items-baseline gap-2">
                    <span className="text-2xl font-bold text-gray-900">
                      ₹{order?.orderItems[0]?.product?.price?.toLocaleString() || '0'}
                    </span>
                    <span className="text-sm text-gray-500">INR</span>
                  </div>
                  <button
                    onClick={() => handleTrackOrder(order?.id)}
                    className="px-6 py-2.5 bg-violet-600 hover:bg-violet-700 text-white rounded-xl transition-all duration-300 text-sm font-medium shadow-lg shadow-violet-200/50 hover:shadow-violet-300/50 flex items-center gap-2"
                  >
                    Track Order
                    <ChevronRight size={16} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const filteredOrders = orders.filter(order =>
    selectedFilters.size === 0 || selectedFilters.has(order.orderStatus)
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-gradient-to-br from-violet-600 to-indigo-600 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-white">My Orders</h1>
            <p className="text-violet-200 mt-2">Track and manage your purchases</p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-64 flex-shrink-0">
            <div className="sticky top-4">
              <div className="bg-white rounded-2xl shadow-sm p-6 space-y-6">
                <div className="flex items-center gap-2 text-violet-600">
                  <Filter size={20} />
                  <h2 className="text-lg font-semibold">Filters</h2>
                </div>
                <div className="space-y-4">
                  {orderStatus.map(({ label, value }) => (
                    <label key={value} className="flex items-center gap-3 cursor-pointer group">
                      <div className="relative flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedFilters.has(value)}
                          onChange={() => {
                            const newFilters = new Set(selectedFilters);
                            if (newFilters.has(value)) {
                              newFilters.delete(value);
                            } else {
                              newFilters.add(value);
                            }
                            setSelectedFilters(newFilters);
                          }}
                          className="w-5 h-5 border-2 border-gray-200 rounded-md text-violet-600 focus:ring-violet-600 transition-colors"
                        />
                      </div>
                      <span className="text-sm text-gray-600 group-hover:text-gray-900">{label}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="grid grid-cols-1 gap-8 ">
              {loading ? (
                <p>Loading...</p>
              ) : (
                filteredOrders.length > 0 ? (
                  filteredOrders.map(order => <OrderCard key={order.id} order={order} />)
                ) : (
                  <p>No orders found</p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
