import React from 'react';
import { motion } from 'framer-motion';
import "./ProductCard.css";
import { useNavigate } from "react-router-dom";

const baseUrl = "https://yasar-2024-25-client.s3.amazonaws.com/";

const ProductCard = ({ product }) => {
  const { title, imageUrls, price, discountedPrice, discountPersent } = product;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/product/${product?.id || product?._id || 2}`);
  };

  return (
    <motion.div 
      onClick={handleNavigate} 
      className='productCard w-[300px] border rounded-lg shadow-md cursor-pointer' 
      initial={{ scale: 1 }} 
      whileHover={{ scale: 1.05 }} 
      whileTap={{ scale: 0.95 }} 
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <div className='h-[300px] overflow-hidden rounded-t-lg'>
        <motion.img 
          className='w-full h-full object-cover transition-transform duration-300' 
          src={baseUrl + imageUrls[0]} 
          alt={title} 
          initial={{ scale: 1 }} 
          whileHover={{ scale: 1.1 }} 
          transition={{ duration: 0.3 }} 
        />
      </div>
      <div className='textPart bg-white p-4 rounded-b-lg'>
        <p className='font-bold text-lg'>{title}</p>
        <p className='text-lg font-semibold text-gray-800'>₹{discountedPrice}</p>
        <div className='flex space-x-2 items-center'>
          <p className='text-gray-400 line-through'>₹{price}</p>
          <p className='text-green-600 font-semibold'>{discountPersent}% off</p>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductCard;
