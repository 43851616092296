import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from '../../../Redux/Customers/Order/Action';
import AddressCard from '../adreess/AdreessCard';

export default function AddDeliveryAddressForm({ handleNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem('jwt');
  const { auth } = useSelector((store) => store);
  const [selectedAddress, setSelectedAdress] = useState(null);




  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const address = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      streetAddress: data.get('address'),
      city: data.get('city'),
      state: data.get('state'),
      zipCode: data.get('zip'),
      mobile: data.get('phoneNumber'),
    };

    dispatch(createOrder({ address, jwt, navigate }));
    handleNext();
  };

  const handleCreateOrder = (item) => {
    dispatch(createOrder({ address: item, jwt, navigate }));
    handleNext();
  };

  // Format the time remaining


  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
      {/* Timer Banner */}


      <Grid container spacing={4}>
        {/* Saved Addresses Section */}
        <Grid item xs={12} lg={5}>
          <div className="bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold p-4 border-b">Saved Addresses</h2>
            <div className="h-[30.5rem] overflow-y-auto">
              {auth.user?.addresses.map((item) => (
                <div
                  key={item.id}
                  onClick={() => setSelectedAdress(item)}
                  className={`p-5 border-b transition-colors cursor-pointer ${
                    selectedAddress?.id === item.id ? 'bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                >
                  <AddressCard address={item} />
                  {selectedAddress?.id === item.id && (
                    <button
                      onClick={() => handleCreateOrder(item)}
                      className="mt-4 w-full bg-black text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Deliver Here
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Grid>

        {/* New Address Form Section */}
        <Grid item xs={12} lg={7}>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-lg font-semibold mb-6">Add New Address</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    className="bg-gray-50"
                  />
                </div>
                <div>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    className="bg-gray-50"
                  />
                </div>
              </div>

              <TextField
                required
                id="address"
                name="address"
                label="Address"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                className="bg-gray-50"
              />

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextField
                  required
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  variant="outlined"
                  className="bg-gray-50"
                />
                <TextField
                  required
                  id="state"
                  name="state"
                  label="State/Province/Region"
                  fullWidth
                  variant="outlined"
                  className="bg-gray-50"
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextField
                  required
                  id="zip"
                  name="zip"
                  label="Zip / Postal code"
                  fullWidth
                  variant="outlined"
                  className="bg-gray-50"
                />
                <TextField
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  fullWidth
                  variant="outlined"
                  className="bg-gray-50"
                />
              </div>

              <button
                type="submit"
                className="w-full bg-black text-white py-3 px-6 rounded-md c transition-colors text-lg font-medium"
              >
                Deliver Here
              </button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
