import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePayment } from "../../../Redux/Customers/Payment/Action";
import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import { green, teal } from "@mui/material/colors";
import { getOrderById } from "../../../Redux/Customers/Order/Action";
import OrderTraker from "../orders/OrderTraker";
import AddressCard from "../adreess/AdreessCard";
import { useParams } from "react-router-dom";

const PaymentSuccess = () => {
  const [paymentId, setPaymentId] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const { orderId } = useParams();

  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  const { order } = useSelector((store) => store);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setPaymentId(urlParams.get("razorpay_payment_id"));
    setReferenceId(urlParams.get("razorpay_payment_link_reference_id"));
    setPaymentStatus(urlParams.get("razorpay_payment_link_status"));
  }, []);

  useEffect(() => {
    const appliedCouponCode = localStorage.getItem('appliedCouponCode');
    if (paymentId && paymentStatus === "paid") {
      const data = { orderId, paymentId, jwt, appliedCouponCode };
      dispatch(updatePayment(data));
      dispatch(getOrderById(orderId));
    }
  }, [orderId, paymentId, paymentStatus, jwt, dispatch]);

  return (
    <div className="px-4 py-8 lg:px-24">
      <div className="flex flex-col justify-center items-center">
        <Alert
          variant="filled"
          severity="success"
          sx={{
            mb: 6,
            width: "fit-content",
            backgroundColor: green[600],
            color: "white",
            borderRadius: 3,
            boxShadow: 3,
          }}
        >
          <AlertTitle sx={{ fontWeight: "bold" }}>Payment Success</AlertTitle>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Congratulations! Your Order has been successfully placed.
          </Typography>
        </Alert>
      </div>

      <OrderTraker activeStep={1} />

      <Grid container spacing={4} className="py-5 pt-20">
        {order.order?.orderItems.map((item, index) => (
          <Grid
            container
            item
            key={index}
            sx={{
              borderRadius: 2,
              padding: 3,
              border: `1px solid ${teal[400]}`,
              boxShadow: 2,
              backgroundColor: "#f9fafb",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <div className="flex items-center">
                <img
                  className="w-[5rem] h-[5rem] object-cover object-top"
                  src={item?.product.imageUrl}
                  alt="product"
                  style={{
                    borderRadius: 8,
                    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
                  }}
                />
                <div className="ml-6 space-y-2">
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {item.product.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    Color: {item.color || "N/A"} | Size: {item.size}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    Seller: {item.product.brand}
                  </Typography>
                  <Typography variant="h6" sx={{ color: green[700] }}>
                    ₹{item.price}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <AddressCard address={order.order?.shippingAddress} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PaymentSuccess;






// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { updatePayment } from "../../../Redux/Customers/Payment/Action";
// import { Alert, AlertTitle, Box, Grid } from "@mui/material";
// import { deepPurple } from "@mui/material/colors";
// import StarIcon from "@mui/icons-material/Star";
// import { getOrderById } from "../../../Redux/Customers/Order/Action";
// import OrderTraker from "../orders/OrderTraker";
// import AddressCard from "../adreess/AdreessCard";
// import { useParams } from "react-router-dom";

// const PaymentSuccess = () => {
//   // razorpay_payment_link_reference_id
//   // razorpay_payment_id
//   const [paymentId, setPaymentId] = useState("");
//   const [referenceId, setReferenceId] = useState("");
//   const [paymentStatus, setPaymentStatus] = useState("");
//   const {orderId}=useParams();

  

//   const jwt = localStorage.getItem("jwt");
//   const dispatch = useDispatch();
//   const { order } = useSelector((store) => store);

//   useEffect(() => {
//     console.log("orderId",orderId)
//     const urlParams = new URLSearchParams(window.location.search);
//     setPaymentId(urlParams.get("razorpay_payment_id"));
//     setReferenceId(urlParams.get("razorpay_payment_link_reference_id"));
//     setPaymentStatus(urlParams.get("razorpay_payment_link_status"));
//   }, []);

//   useEffect(() => {
//     var appliedCouponCode = localStorage.getItem('appliedCouponCode');
//     if (paymentId && paymentStatus === "paid") {
//       const data = { orderId, paymentId, jwt ,appliedCouponCode};
//       dispatch(updatePayment(data));
//       dispatch(getOrderById(orderId));
//     }
//   }, [orderId, paymentId]);

//   return (
//     <div className="px-2 lg:px-36">
//       <div className="flex flex-col justify-center items-center">
//         <Alert
//           variant="filled"
//           severity="success"
//           sx={{ mb: 6, width: "fit-content" }}
//         >
//           <AlertTitle>Payment Success</AlertTitle>
//           Congratulation Your Order Get Placed
//         </Alert>
//       </div>

//       <OrderTraker activeStep={1}/>

//       <Grid container className="space-y-5 py-5 pt-20">
//         {order.order?.orderItems.map((item) => (
//           <Grid
//             container
//             item
//             className="shadow-xl rounded-md p-5 border"
//             sx={{ alignItems: "center", justifyContent: "space-between" }}
//           >
//             <Grid item xs={6}>
//               {" "}
//               <div className="flex  items-center ">
//                 <img
//                   className="w-[5rem] h-[5rem] object-cover object-top"
//                   src={item?.product.imageUrl}
//                   alt=""
//                 />
//                 <div className="ml-5 space-y-2">
//                   <p className="">{item.product.title}</p>
//                   <p className="opacity-50 text-xs font-semibold space-x-5">
//                     <span>Color: pink</span> <span>Size: {item.size}</span>
//                   </p>
//                   <p>Seller: {item.product.brand}</p>
//                   <p>₹{item.price}</p>
//                 </div>
//               </div>
//             </Grid>
//             <Grid item>
//               <AddressCard address={order.order?.shippingAddress} />
//             </Grid>
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default PaymentSuccess;
