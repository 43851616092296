import React from "react";

const RefundCancellationPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Refund and Cancellation Policy</h1>
      <p>
        At Kalaksha, we prioritize customer satisfaction and strive to provide a seamless shopping experience. This Refund and Cancellation Policy outlines the terms under which we handle order cancellations and product replacements. We encourage you to read this policy carefully to understand your rights and obligations.
      </p>

      <h2 style={styles.subheading}>1. Replacement Policy</h2>
      <p>
        We do not offer refunds under any circumstances. Instead, we provide replacements for products that are either damaged upon arrival or if you receive the incorrect color or product. To initiate a replacement:
      </p>
      <ul>
        <li>You must notify us of any issues within 7 days of receiving your order.</li>
        <li>Contact our customer service team with your order number and a description of the issue.</li>
        <li>Provide photographic evidence of the damage or incorrect item to expedite the process.</li>
        <li>All replacements are subject to stock availability, and we will inform you if the desired replacement is out of stock.</li>
      </ul>

      <h2 style={styles.subheading}>2. Cancellation Policy</h2>
      <p>
        You can cancel your order only before it has been dispatched. Once the order has been shipped, it cannot be canceled. To cancel your order:
      </p>
      <ul>
        <li>Contact our customer support team as soon as possible via email or phone.</li>
        <li>Provide your order number and request cancellation.</li>
        <li>Our team will confirm whether the cancellation is possible and guide you through the process.</li>
      </ul>

      <h2 style={styles.subheading}>3. Non-Eligible Items for Replacement</h2>
      <p>
        Certain items are not eligible for replacement, including:
      </p>
      <ul>
        <li>Items that have been damaged due to customer misuse or mishandling.</li>
        <li>Products that have been used, altered, or modified in any way after delivery.</li>
        <li>Items sold as part of promotional offers or clearance sales.</li>
      </ul>

      <h2 style={styles.subheading}>4. How to Request a Replacement</h2>
      <p>
        If you wish to request a replacement, please follow these steps:
      </p>
      <ul>
        <li>Email us at  <b>support@kalaksha.com</b> or call our customer service number at +91 97422 12377.</li>
        <li>Include your order number and details about the issue.</li>
        <li>Attach photographic evidence of the damage or incorrect item.</li>
        <li>Our customer service team will respond promptly to guide you through the replacement process.</li>
      </ul>

      <h2 style={styles.subheading}>5. Customer Service Contact Information</h2>
      <p>
        For any questions regarding our Refund and Cancellation Policy, please feel free to reach out to our customer service team at:
      </p>
      <ul>
        <li>Email: <b>support@kalaksha.com</b></li>
        <li>Phone: <b>+91 97422 12377</b></li>
      </ul>
      <p>
        We appreciate your understanding and support. Thank you for choosing Kalaksha!
      </p>
    </div>
  );
};

const styles = {
  container: { padding: "50px 200px", fontFamily: "Arial, sans-serif", lineHeight: "1.8" },
  heading: { fontSize: "2.5em", marginBottom: "20px" },
  subheading: { fontSize: "1.8em", marginTop: "20px", marginBottom: "10px" },
};

export default RefundCancellationPolicy;
