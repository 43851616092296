import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddDeliveryAddressForm from "./AddAddress";
import { useLocation, useNavigate } from "react-router-dom";
import OrderSummary from "./OrderSummary";
import { useMediaQuery } from "@mui/material";

const steps = ["Login", "Delivery Address", "Order Summary", "Payment"];

// Custom Stepper component
const RoyalStepper = ({ activeStep, steps }) => {
  return (
    <div className="flex items-center justify-between w-full mb-8">
      {steps.map((label, index) => (
        <div key={label} className="flex flex-col items-center relative flex-1">
          {/* Connector Line */}
          {index < steps.length - 1 && (
            <div 
              className={`absolute w-full h-0.5 top-5 left-[50%] -z-10 
                ${index < activeStep ? 'bg-purple-600' : 'bg-gray-200'}`}
            />
          )}
          
          {/* Step Circle */}
          <div 
            className={`w-10 h-10 rounded-full flex items-center justify-center 
              transition-all duration-500 border-2
              ${index < activeStep ? 'bg-purple-600 border-purple-700' : 
                index === activeStep ? 'bg-white border-purple-600' : 'bg-white border-gray-300'}
              ${index <= activeStep ? 'shadow-lg' : ''}`}
          >
            <span className={`text-sm font-bold
              ${index < activeStep ? 'text-white' : 
                index === activeStep ? 'text-purple-600' : 'text-gray-400'}`}
            >
              {index + 1}
            </span>
          </div>

          {/* Step Label */}
          <span className={`mt-3 text-sm font-medium transition-colors duration-300
            ${index <= activeStep ? 'text-purple-700' : 'text-gray-400'}`}
          >
            {label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [timeLeft, setTimeLeft] = React.useState(null);
  const [timerActive, setTimerActive] = React.useState(true);
  const step = queryParams.get('step');
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  React.useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const endOfDay = new Date();
      endOfDay.setHours(17, 0, 0, 0);
      const timeDifference = endOfDay - now;

      if (timeDifference <= 0) {
        setTimerActive(false);
      } else {
        setTimeLeft(timeDifference);
      }
    };

    calculateTimeLeft();
    const timerInterval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const formatTimeLeft = (time) => {
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    navigate(`/checkout?step=${step-1}`);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePayment = () => {
    console.log("handle payment");
  };

  return (
    <Box className="px-5 lg:px-32 mt-[50px]" sx={{ width: "100%" }}>
      {/* Replace MUI Stepper with custom RoyalStepper */}
      <RoyalStepper activeStep={parseInt(step)} steps={steps} />

      <div className="bg-orange-50 border border-orange-100 mt-6 rounded-lg p-3 mb-6 text-center">
        {timerActive ? (
          <p className="text-gray-700">
            Hurry up! order now after{' '}
            <span className="font-semibold text-orange-600">
              {timeLeft ? formatTimeLeft(timeLeft) : 'Loading...'}
              {' '}
            </span>
            your order dispatch is tomorrow.
          </p>
        ) : (
          <p className="text-red-600 font-semibold">
            Your dispatch is tomorrow.
          </p>
        )}
      </div>

      {activeStep === steps.length ? (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="h5" color="textSecondary">
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button 
              variant="contained" 
              onClick={handleReset} 
              sx={{ backgroundColor: 'orange', '&:hover': { backgroundColor: '#ff7c00' } }}
            >
              Start Over
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={step == 1}
              sx={{
                borderColor: 'orange',
                color: 'orange',
                '&:hover': {
                  borderColor: '#ff7c00',
                  color: '#ff7c00',
                },
              }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1" }} />
          </Box>

          <div className="my-5">
            {step == 2 ? <AddDeliveryAddressForm handleNext={handleNext} /> : <OrderSummary />}
          </div>
        </Box>
      )}
    </Box>
  );
}