import React from "react";
import { useDispatch } from "react-redux";
import { removeCartItem, updateCartItem } from "../../../Redux/Customers/Cart/Action";
import { S3_BASE_URL } from "../../../config/api";

// Custom Button Components
const Button = ({ variant = "default", className = "", children, ...props }) => {
  const baseStyles = "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none";
  const variantStyles = {
    default: "bg-black text-white hover:bg-gray-800 h-10 px-4 py-2",
    text: "text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md px-3 py-2",
    icon: "p-1 hover:bg-gray-100 rounded-full text-gray-600 hover:text-gray-900"
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

// Custom Icons
const PlusIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="16" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

const MinusIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

const CartItem = ({ item, showButton }) => {
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");

  const handleRemoveItemFromCart = () => {
    const data = { cartItemId: item?.id, jwt };
    dispatch(removeCartItem(data));
  };

  const handleUpdateCartItem = (num) => {
    const data = {
      data: { quantity: item.quantity + num },
      cartItemId: item?.id,
      jwt
    };
    dispatch(updateCartItem(data));
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden transition-all hover:shadow-md">
      <div className="p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4">
          {/* Image */}
          <div className="relative w-full sm:w-[120px] h-[120px] rounded-md overflow-hidden flex-shrink-0">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={S3_BASE_URL+ item?.product.imageUrls[0]}
              alt={item?.product?.title}
            />
          </div>

          {/* Content */}
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-semibold text-gray-900 mb-1 truncate">
              {item?.product?.title}
            </h3>
            
            <div className="mt-1 flex items-center gap-2">
              <span className="text-2xl font-bold text-gray-900">
                ₹{item?.product.price}
              </span>
            </div>

            {showButton && (
              <div className="mt-4 flex flex-col sm:flex-row sm:items-center gap-4">
                {/* Quantity Controls */}
                <div className="flex items-center space-x-2">
                  <Button
                    variant="icon"
                    onClick={() => handleUpdateCartItem(-1)}
                    disabled={item?.quantity <= 1}
                    aria-label="Decrease quantity"
                  >
                    <MinusIcon className="w-6 h-6" />
                  </Button>

                  <span className="w-16 h-10 flex items-center justify-center border border-gray-200 rounded-md bg-white text-gray-900 font-medium">
                    {item?.quantity}
                  </span>

                  <Button
                    variant="icon"
                    onClick={() => handleUpdateCartItem(1)}
                    aria-label="Increase quantity"
                  >
                    <PlusIcon className="w-6 h-6" />
                  </Button>
                </div>

                {/* Remove Button */}
                <Button
                  variant="text"
                  onClick={handleRemoveItemFromCart}
                  className="sm:ml-auto"
                >
                  Remove
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;