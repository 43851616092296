import { createTheme } from "@mui/material/styles";


//main
const customTheme = createTheme({
  palette: {
    mode: "light", // Set the custom color mode name here
    primary: {
      main: '#006655',
    },
    secondary: {
      main: '#F9F6EE',
    },
    white:{
      main:"#fff"
    },
    orange:{
      main:"#ffdb0f"
    },
    
    background: {
      default: '',
      paper: '#002e26',
      // paper:"rgb(0, 0, 22)"
    },
  },
 



  
});

const DashTheme = createTheme({
  palette: {
    mode: "light", // Set the custom color mode name here
    primary: {
      main: '#006655',
    },
    secondary: {
      main: '#F9F6EE',
    },
    text:{
      main: '#000',
    },
    orange:{
      main:"#ffdb0f"
    },
    
    background: {
      default: '',
      paper: '#d6fff8',
      // paper:"rgb(0, 0, 22)"
    },
  },
});


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9155FD',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

const customerTheme = createTheme({
  palette: {
    mode: "light", // Set the custom color mode name here
    primary: {
      main: '#9155FD',
    },
    secondary: {
      main: '#f48fb1',
    },
    white:{
      main:"#000"
    },
    orange:{
      main:"#ffdb0f"
    },
    
    background: {
      default: '',
      // paper: '#121019',
      paper:"white"
    },
  },
 
  
});

export {customTheme,darkTheme,customerTheme, DashTheme };
