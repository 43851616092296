import React, { useState, useEffect } from 'react';
import { ShoppingCart, CreditCard, X  , Heart} from 'lucide-react';
import { S3_BASE_URL } from '../../../../config/api';

const BottomCart = ({title ,  desc , price , imgeurl}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Current scroll position from the top
      const viewportHeight = window.innerHeight; // Total viewport height

      // Show the cart when the user scrolls beyond 50-60% of the viewport height
      if (scrollPosition >= viewportHeight * 0.5) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  const ProductInfo = () => (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <img
          src={`${S3_BASE_URL}${imgeurl}`}
          alt="Product"
          className="w-20 h-20 object-cover rounded-lg shadow-md"
        />
        <span className="absolute -top-2 -right-2 bg-yellow-500 text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center">
          1
        </span>
      </div>
      <div className="flex-1 min-w-0">
        <h3 className="text-sm font-semibold text-gray-900 truncate">
          { title || "Designer Georgette Bridal Saree"}
        </h3>
        <p className="mt-1 text-xs text-gray-500 truncate">
        { desc|| "A beautiful bridal saree perfect for your special day"}
          
        </p>
        <div className="mt-1 flex items-center">
          <span className="text-lg font-bold text-gray-900">
          { price || " ₹1,500"}
           </span>
          {/* <span className="ml-2 text-sm text-gray-500 line-through">₹2,000</span>
          <span className="ml-2 text-xs font-medium text-green-600">25% off</span> */}
        </div>
      </div>
    </div>
  );

  const ActionButtons = () => (
    <div className="flex items-center space-x-3">
      <button 
        className="flex items-center space-x-2 px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-all duration-300 ease-in-out"
      >
        <ShoppingCart size={18} />
        <span>Add to Cart</span>
      </button>
      <button className="p-2 border border-gray-300 rounded-md hover:bg-gray-100">
              <Heart className="w-5 h-5" />
            </button>
    </div>
  );

  return (
    <>
      {/* Backdrop overlay */}
      <div 
        className={`fixed inset-0 transition-opacity duration-300 ease-in-out
          ${isVisible ? 'opacity-50' : 'opacity-0 pointer-events-none'}`}
        onClick={handleClose}
      />

      {/* Cart container */}
      <div 
        className={`fixed inset-x-0 bottom-0 z-50 transition-transform duration-500 ease-in-out
          ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}
      >
        <div className="bg-white border-t border-gray-200 shadow-2xl rounded-t-xl">
          {/* Close button */}
          {/* <button
            onClick={handleClose}
            className="absolute -top-12 right-4 bg-white rounded-full p-2 shadow-xl hover:bg-gray-100 transition-all duration-300 ease-in-out"
          >
            <X size={20} className="text-gray-600" />
          </button> */}

          {/* Main content */}
          <div className="max-w-6xl mx-auto px-4 py-4">
            <div className="flex items-center justify-between flex-wrap">
              <ProductInfo />
              <div className="hidden sm:block">
                <ActionButtons />
              </div>
            </div>

            {/* Mobile view buttons */}
            <div className="sm:hidden mt-4 w-full">
              <ActionButtons />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomCart;
