import React from 'react';
 import sm from '../../../../asstes/Footer/sm.avif'
 import hl from '../../../../asstes/Footer/hl.avif'

const CertificationDisplay = () => {
  const certifications = [
    {
      id: 1,
      src:  sm , // Replace with your actual AVIF logo path
      alt: "Silk Mark Certified by Central Silk Board of India",
      title: "Silk Mark Certified",
      subtitle: "by Central Silk Board of India"
    },
    {
      id: 2,
      src: hl, // Replace with your actual AVIF logo path
      alt: "Handloom Mark from Ministry of Textiles",
      title: "Handloom Mark",
      subtitle: "from Ministry of Textiles"
    }
  ];

  return (
    <div className="w-full max-w-6xl mx-auto p-4">
      <div className="relative pb-12">
        {/* Changed to always show 2 columns using grid-cols-2 */}
        <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 items-center justify-center">
          {certifications.map((cert) => (
            <div key={cert.id} className="flex flex-col sm:flex-row items-center space-x-4">
              <div className="w-full sm:w-1/3 p-2 bg-white rounded-lg flex justify-center">
                <img
                  src={cert.src}
                  alt={cert.alt}
                  className="w-[50%] lg:w-full h-auto object-contain"
                />
              </div>
              {/* Text Section */}
              <div className="w-full sm:w-1/2 text-center sm:text-left mt-2 sm:mt-0">
                <h3 className="text-xs lg:text-[12px] font-semibold text-gray-800">{cert.title}</h3>
                <p className="text-xs lg:text-[8px] text-gray-600">{cert.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
        
        {/* Bottom text */}
        <div className="absolute bottom-0 left-0 right-0">
          <div className="bg-white py-2">
            <p className="text-center text-sm lg:text-lg font-medium text-gray-600">
              100% certified by Indian standards
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationDisplay;
