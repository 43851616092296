import React from 'react';

const SareeIntro = ({ title, description, backgroundImage }) => {
  return (
    <div className="relative w-full p-4 overflow-hidden rounded-lg">
      {/* Background Container */}
      <div 
        className="relative h-[300px] w-full rounded-xl bg-cover bg-center p-6"
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        }}
      >
        
        {/* Black Overlay for better text readability */}
        <div className="absolute inset-0 bg-black/60 rounded-lg" />
        
        {/* Content Container */}
        <div className="relative h-full flex flex-col justify-center items-center text-center z-10">
          <h2 className="text-2xl lg:text-4xl font-bold text-white mb-4 tracking-wide">
            {title}
          </h2>
          <p className=" text-[10px]  text-justify lg:text-lg text-white/90 font-light lg:leading-relaxed">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SareeIntro;
