import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderById } from '../../../Redux/Customers/Order/Action';
import { createPayment } from '../../../Redux/Customers/Payment/Action';
import { S3_BASE_URL } from '../../../config/api';

const validCoupons = {
  'LAUNCH40': 0.40,  // 40% discount
  'FESTIVE20': 0.20, // 20% discount
};

const OrderSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  const jwt = localStorage.getItem("jwt");
  const { order } = useSelector(state => state);
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [isValid, setIsValid] = useState(null);

  const validateCoupon = (code) => {
    if (validCoupons[code]) {
      setDiscount(validCoupons[code]);
      setIsValid(true);
    } else {
      setDiscount(0);
      setIsValid(false);
    }
  };

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, [orderId, dispatch]);

  useEffect(() => {
    const savedCouponCode = localStorage.getItem('appliedCouponCode');
    setCouponCode(savedCouponCode || '');
    if (savedCouponCode) {
      validateCoupon(savedCouponCode);
    }
  }, []);

  const handleCreatePayment = () => {
    const data = {
      orderId: order.order?.id,
      jwt,
      appliedCouponCode: couponCode,
    };
    dispatch(createPayment(data));
  };

  if (!order.order) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6 bg-gray-50 min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column - Order Details and Address */}
        <div>
          <div className="bg-white rounded-lg p-6 shadow-md mb-8">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Shipping Details</h2>
            <div className="space-y-4">
              <div className="flex items-center text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
                <span className="ml-3">{order.order?.shippingAddress?.firstName} {order.order?.shippingAddress?.lastName}</span>
              </div>
              <div className="flex items-start text-gray-600 text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500 mt-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                </svg>
                <span className="ml-3">{order.order?.shippingAddress?.streetAddress}, {order.order?.shippingAddress?.city}, {order.order?.shippingAddress?.state} - {order.order?.shippingAddress?.zipCode}</span>
              </div>
              <div className="flex items-center text-gray-600 text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <span className="ml-3">{order.order?.shippingAddress?.mobile}</span>
              </div>
            </div>
          </div>

          {/* Order Items */}
          <div className="space-y-6">
            {order.order?.orderItems.map((item) => (
              <div key={item.id} className="bg-white rounded-lg p-5 shadow-md">
                <div className="flex gap-4">
                  <img 
                    src={`${S3_BASE_URL + item.product.imageUrls[0]}`}
                    alt={item.product?.title}
                    className="w-20 h-20 object-cover rounded"
                  />
                  <div className="flex-1">
                    <h3 className="font-medium text-gray-800">{item.product?.title}</h3>
                    <p className="text-sm text-gray-500">Quantity: {item.quantity}</p>
                    <p className="font-semibold text-gray-800">₹ {item.price}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Column - Purchase Summary */}
        <div className="w-full ">
          <div className="bg-white rounded-lg p-6 shadow-md">
            <h2 className="text-xl font-semibold mb-6 text-gray-800">Purchase Summary</h2>

            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-600">Price ({order.order?.totalItem} items)</span>
                <span>₹ {order.order?.totalPrice}</span>
              </div>

              <div className="flex justify-between">
                <span className="text-gray-600">Discount [{couponCode}]</span>
                <span className="text-green-600">-₹ {order.order?.totalPrice * discount}</span>
              </div>

              <div className="flex justify-between">
                <span className="text-gray-600">Shipping</span>
                <span className="text-green-600">Free</span>
              </div>

              <div className="pt-4 border-t">
                <div className="flex justify-between items-center">
                  <span className="font-medium">Total Amount</span>
                  <span className="font-semibold text-lg text-gray-800">₹ {(order.order?.totalPrice - order.order?.totalPrice * discount).toFixed(2)}</span>
                </div>
              </div>

              <button
                onClick={handleCreatePayment}
                className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white py-3 rounded-lg font-medium transition-all duration-300"
              >
                Proceed to Payment
              </button>

              <div className="flex items-center justify-center gap-2 text-sm text-gray-500 mt-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
                <span>All payments are secure and encrypted.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
