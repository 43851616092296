import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Maximize2 } from 'lucide-react';
import { S3_BASE_URL } from '../../../config/api';


const ProductGallery = ({ imageUrls = [] }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const baseURL = S3_BASE_URL;
  const aspectRatio = 1459 / 2153;

 

  // Sorted image URLs


  const handleMouseMove = (e, index) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setMousePosition({ x, y });
    setZoomedImage(index);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sortedImageUrls.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sortedImageUrls.length) % sortedImageUrls.length);
  };

  // Image labels based on index
  const imageLabels = [
    { index: 0, label: 'Saree front' },
    { index: 1, label: 'Pallu' },
    { index: 2, label: 'Body' },
    { index: 3, label: 'Blouse' },
    { index: 4, label: 'Design / Close Up' },
    { index: 5, label: 'Border' },
  ];


  const sortImageUrls = (urls) => {
    return [...urls].sort((a, b) => {
      const aNumber = parseInt(a.split('-')[1].split('_')[0]);
      const bNumber = parseInt(b.split('-')[1].split('_')[0]);
      return aNumber - bNumber;
    });
  };
  const sortedImageUrls = sortImageUrls(imageUrls);
  

  return (
    <>
    <div className="hidden lg:grid grid-cols-2 gap-2">
        {sortedImageUrls.map((url, index) => (
          <div
            key={index}
            className="relative w-full overflow-hidden"
            style={{ aspectRatio }}
            onMouseMove={(e) => handleMouseMove(e, index)}
            onMouseEnter={() => setIsZoomed(true)}
            onMouseLeave={() => setIsZoomed(false)}
          >
            <img
              src={`${baseURL}${url}`}
              alt={`Product view ${index + 1}`}
              className="w-full h-full object-cover"
            />

            {isZoomed && zoomedImage === index && (
              <div className="absolute top-0 right-0 w-32 h-32 mt-2 mr-2 overflow-hidden rounded-lg border border-gray-200 bg-white">
                <img
                  src={`${baseURL}${url}`}
                  alt="Zoomed view"
                  className="w-full h-full object-cover"
                  style={{
                    transform: 'scale(2)',
                    transformOrigin: `${mousePosition.x * 100}% ${mousePosition.y * 100}%`,
                  }}
                />
              </div>
            )}

            {isZoomed && zoomedImage === index && (
              <div
                className="absolute w-16 h-16 rounded-full border-2 bg-white opacity-50 border-purple-500 pointer-events-none flex items-center justify-center"
                style={{
                  top: `${mousePosition.y * 100}%`,
                  left: `${mousePosition.x * 100}%`,
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <span className="text-purple-500 text-2xl font-bold">+</span>
              </div>
            )}

            {/* Label for the image */}
              <div
                className="absolute bg-gradient-to-b from-purple-600 to-purple-900 text-white text-sm py-2 px-4 rounded-br-xl transform -rotate-90 origin-top-left"
              >
                {imageLabels.find((label) => label.index === index)?.label}
              </div>

          </div>
        ))}
      </div>
    

    <div className="w-full max-w-2xl mx-auto lg:hidden">
      <div className="relative">
        {/* Main Image Container */}
        <div className="relative aspect-[3/4] mb-4 bg-gray-100 rounded-lg overflow-hidden">
          <img
            src={`${S3_BASE_URL}${sortedImageUrls[currentImageIndex]}`}
            alt={`Product view ${currentImageIndex + 1}`}
            className="w-full h-full object-cover"
          />
          
          {/* Fullscreen button */}
          <button 
            onClick={() => setIsFullscreen(true)}
            className="absolute top-4 right-4 bg-black/50 hover:bg-black/70 p-2 rounded-lg transition-colors"
            aria-label="View fullscreen"
          >
            <Maximize2 className="w-5 h-5 text-white" />
          </button>
        </div>

        {/* Thumbnails with arrows - Mobile friendly */}
        <div className="relative px-8">
          <button
            onClick={prevImage}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-100 p-2 rounded-full shadow-lg z-10"
            aria-label="Previous image"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>

          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex gap-2 justify-start items-center py-2">
              {sortedImageUrls.map((url, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`flex-shrink-0 w-16 h-20 rounded-lg overflow-hidden transition-all ${
                    currentImageIndex === index 
                      ? 'ring-2 ring-black opacity-100' 
                      : 'ring-1 ring-gray-200 opacity-70 hover:opacity-100'
                  }`}
                >
                  <img
                   src={`${S3_BASE_URL}${url}`}

                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>

          <button
            onClick={nextImage}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-100 p-2 rounded-full shadow-lg z-10"
            aria-label="Next image"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Fullscreen Modal */}
      {isFullscreen && (
        <div 
          className="fixed inset-0 bg-black z-50 flex items-center justify-center"
          onClick={() => setIsFullscreen(false)}
        >
          <img
            src={`${S3_BASE_URL}${sortedImageUrls[currentImageIndex]}`}
            alt={`Product view ${currentImageIndex + 1}`}
            className="max-h-screen max-w-screen-lg object-contain"
          />
          <button
            onClick={() => setIsFullscreen(false)}
            className="absolute top-4 right-4 bg-white/10 text-white w-10 h-10 flex items-center justify-center rounded-full hover:bg-white/20"
          >
            ×
          </button>
        </div>
      )}
    </div>
    </>
  );
};

export default ProductGallery;


// import React, { useState } from 'react';
// import { S3_BASE_URL } from '../../../config/api';

// const ProductGallery = ({ imageUrls = [] }) => {
//   const [isZoomed, setIsZoomed] = useState(false);
//   const [zoomedImage, setZoomedImage] = useState(null);
//   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   const baseURL = S3_BASE_URL;
//   const aspectRatio = 1459 / 2153;

//   // Sorting function to sort image URLs by index
//   const sortImageUrls = (imageUrls) => {
//     return [...imageUrls].sort((a, b) => {
//       const aNumber = parseInt(a.split('-')[1].split('_')[0]);
//       const bNumber = parseInt(b.split('-')[1].split('_')[0]);
//       return aNumber - bNumber;
//     });
//   };

//   // Sorted image URLs
//   const sortedImageUrls = sortImageUrls(imageUrls);

//   const handleMouseMove = (e, index) => {
//     const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
//     const x = (e.clientX - left) / width;
//     const y = (e.clientY - top) / height;
//     setMousePosition({ x, y });
//     setZoomedImage(index);
//   };

//   const nextImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sortedImageUrls.length);
//   };

//   const prevImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sortedImageUrls.length) % sortedImageUrls.length);
//   };

//   // Image labels based on index
//   const imageLabels = [
//     { index: 0, label: 'Saree front' },
//     { index: 1, label: 'Pallu' },
//     { index: 2, label: 'Body' },
//     { index: 3, label: 'Blouse' },
//     { index: 4, label: 'Design / Close Up' },
//     { index: 5, label: 'Border' },
//   ];

//   return (
//     <div className="w-full">
//       {/* Desktop view without slider */}
//       <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
//         {sortedImageUrls.map((url, index) => (
//           <div
//             key={index}
//             className="relative w-full overflow-hidden"
//             style={{ aspectRatio }}
//             onMouseMove={(e) => handleMouseMove(e, index)}
//             onMouseEnter={() => setIsZoomed(true)}
//             onMouseLeave={() => setIsZoomed(false)}
//           >
//             <img
//               src={`${baseURL}${url}`}
//               alt={`Product view ${index + 1}`}
//               className="w-full h-full object-cover"
//             />

//             {isZoomed && zoomedImage === index && (
//               <div className="absolute top-0 right-0 w-32 h-32 mt-2 mr-2 overflow-hidden rounded-lg border border-gray-200 bg-white">
//                 <img
//                   src={`${baseURL}${url}`}
//                   alt="Zoomed view"
//                   className="w-full h-full object-cover"
//                   style={{
//                     transform: 'scale(2)',
//                     transformOrigin: `${mousePosition.x * 100}% ${mousePosition.y * 100}%`,
//                   }}
//                 />
//               </div>
//             )}

//             {isZoomed && zoomedImage === index && (
//               <div
//                 className="absolute w-16 h-16 rounded-full border-2 bg-white opacity-50 border-purple-500 pointer-events-none flex items-center justify-center"
//                 style={{
//                   top: `${mousePosition.y * 100}%`,
//                   left: `${mousePosition.x * 100}%`,
//                   transform: 'translate(-50%, -50%)',
//                 }}
//               >
//                 <span className="text-purple-500 text-2xl font-bold">+</span>
//               </div>
//             )}

//             {/* Label for the image */}
//               <div
//                 className="absolute bg-gradient-to-b from-purple-600 to-purple-900 text-white text-sm py-2 px-4 rounded-br-xl transform -rotate-90 origin-top-left"
//               >
//                 {imageLabels.find((label) => label.index === index)?.label}
//               </div>

//           </div>
//         ))}
//       </div>

//       {/* Mobile and tablet view with slider */}
//       <div className="block md:hidden relative w-full overflow-hidden" style={{ aspectRatio }}>
//         <img
//           src={`${baseURL}${sortedImageUrls[currentImageIndex]}`}
//           alt={`Product view ${currentImageIndex + 1}`}
//           className="w-full h-full object-cover"
//           onMouseEnter={() => setIsZoomed(true)}
//           onMouseLeave={() => setIsZoomed(false)}
//           onMouseMove={(e) => handleMouseMove(e, currentImageIndex)}
//         />

//         <button
//           onClick={prevImage}
//           className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 rounded-full p-4 shadow hover:bg-gray-100"
//         >
//           &lt;
//         </button>
//         <button
//           onClick={nextImage}
//           className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 rounded-full p-4 shadow hover:bg-gray-100"
//         >
//           &gt;
//         </button>

//         <div className="flex justify-center mt-4">
//           {sortedImageUrls.map((_, index) => (
//             <button
//               key={index}
//               onClick={() => setCurrentImageIndex(index)}
//               className={`w-2 h-2 rounded-full mx-1 ${currentImageIndex === index ? 'bg-purple-500' : 'bg-gray-300'}`}
//             />
//           ))}
//         </div>
        

//         {/* Label for the image in mobile view */}
//         className="absolute bg-gradient-to-b from-purple-600 to-purple-900 text-white text-sm py-8 px-4 rounded-br-xl transform -rotate-90 origin-top-left"
//         <div className="absolute bottom-0 left-0 bg-gradient-to-b from-purple-600 to-purple-900 py-4 px-8 text-white text-xl rounded-br-xl">
//   {imageLabels.find((label) => label.index === currentImageIndex)?.label}
// </div>





//       </div>
//     </div>
//   );
// };

// export default ProductGallery;
