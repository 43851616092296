import React, { useEffect, useState } from "react";
import { API_BASE_URL, S3_BASE_URL } from "../config/api";
import { FaShoppingCart, FaTrash, FaHeart } from "react-icons/fa";

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addingToCart, setAddingToCart] = useState({});

  // const sortedAllProducts = response.data.body.productList.map((product) => {
  //   const imageUrls = product.imageUrls.sort((a, b) => {
  //     const aIndex = a.indexOf(product.skuNumber);
  //     const bIndex = b.indexOf(product.skuNumber);
  //     const aNum = parseInt(a.slice(aIndex + product.skuNumber.length + 1));
  //     const bNum = parseInt(b.slice(bIndex + product.skuNumber.length + 1));
  //     return aNum - bNum;
  //   });
  //   return { ...product, imageUrls };
  // });

  useEffect(() => {
    const fetchWishlist = async () => {
      setLoading(true);
      const token = localStorage.getItem("jwt");

      try {
        const response = await fetch(`${API_BASE_URL}/api/wishlist/user/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch wishlist");
        }

        const data = await response.json();
        setWishlistItems(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWishlist();
  }, []);

  const handleAddToCart = async (productId) => {
    setAddingToCart((prev) => ({ ...prev, [productId]: true }));
    try {
      const token = localStorage.getItem("jwt");
      const response = await fetch(`${API_BASE_URL}/api/cart/add/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ productId }),
      });

      if (!response.ok) {
        throw new Error("Failed to add to cart");
      }

      // Optionally remove item from wishlist after adding to cart
      setWishlistItems((prev) => prev.filter((item) => item.product.id !== productId));
    } catch (err) {
      console.error(err);
      alert(err.message || "Something went wrong!");
    } finally {
      setAddingToCart((prev) => ({ ...prev, [productId]: false }));
    }
  };

  const handleRemoveFromWishlist = async (productId) => {
    try {
      const token = localStorage.getItem("jwt");
      const response = await fetch(`${API_BASE_URL}/api/wishlist/remove/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ productId }),
      });

      if (!response.ok) {
        throw new Error("Failed to remove from wishlist");
      }

      setWishlistItems((prev) => prev.filter((item) => item.product.id !== productId));
    } catch (err) {
      console.error(err);
      alert(err.message || "Something went wrong!");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center p-4">
        <div className="flex flex-col items-center">
          <div className="animate-pulse w-16 h-16 bg-gray-300 rounded-full mb-4"></div>
          <p className="text-gray-600 text-sm sm:text-base">Loading your wishlist...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center text-center p-4">
        <FaHeart className="text-5xl sm:text-6xl text-red-300 mb-4" />
        <p className="text-red-500 mb-2 text-sm sm:text-base">Error: {error}</p>
        <h2 className="text-lg sm:text-xl font-semibold">Your Wishlist is Unavailable</h2>
        <p className="text-gray-600 text-sm max-w-md mt-2">
          We're experiencing technical difficulties. Please try again later or contact support.
        </p>
      </div>
    );
  }

  if (wishlistItems.length === 0) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center text-center p-4">
        <FaHeart className="text-5xl sm:text-6xl text-gray-300 mb-4" />
        <h2 className="text-lg sm:text-xl font-semibold">Your Wishlist is Empty</h2>
        <p className="text-gray-600 text-sm max-w-md mt-2">
          Explore our product range and add some items to your wishlist.
        </p>
        <button className="mt-6 px-6 sm:px-8 py-2 sm:py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-all">
          Continue Shopping
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4 sm:p-6">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg sm:text-2xl font-bold flex items-center">
            <FaHeart className="mr-2 text-red-500" />
            My Wishlist
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {wishlistItems.map((item) => (
            <div
              key={item.product.id}
              className="bg-white rounded-lg shadow-md p-8 flex flex-col space-y-4"
            >
              <img
                src={S3_BASE_URL + item.product.imageUrls[0]}
                alt={item.product.title}
                className="w-full h-40 object-cover rounded"
              />
              <h3 className="text-sm sm:text-base font-semibold truncate">
                {item.product.title}
              </h3>
              <p className="text-sm text-gray-700 mb-2">₹{item.product.price.toFixed(2)}</p>
              <p
                className={`text-xs font-semibold ${item.product.quantity > 0 ? "text-green-600" : "text-red-600"
                  }`}
              >
                {item.product.quantity > 0 ? `In stock` : "Out of Stock"}
              </p>
              <div className="flex justify-between items-center">
                <button
                  onClick={() => handleAddToCart(item.product.id)}
                  className={`text-xs sm:text-sm  bg-black  ${item.product.quantity > 0 ? " block" : "hidden"
                    } text-white px-5 py-3 rounded hover:bg-blue-700`}
                  disabled={addingToCart[item.product.id]}
                >
                  {addingToCart[item.product.id] ? "Adding..." : "Add to Cart"}
                </button>
                <button
                  onClick={() => handleRemoveFromWishlist(item.product.id)}
                  className="text-gray-500 hover:text-red-600"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
