import React, { useState, useEffect } from 'react';

const SareeCountdownTimer = ({ endDate, name, description, bgColor = "bg-purple-900", textColor = "text-amber-100" }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = new Date(endDate).getTime() - new Date().getTime();
      
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();

    return () => clearInterval(timer);
  }, [endDate]);

  const TimeUnit = ({ value, label }) => (
    <div className="relative group">
      <div className="absolute inset-0 bg-amber-200 rounded-lg transform rotate-3 group-hover:rotate-6 transition-transform duration-300"></div>
      <div className="relative flex flex-col items-center bg-amber-50 p-4 rounded-lg shadow-lg transform -rotate-3 group-hover:rotate-0 transition-transform duration-300">
        <div className="text-2xl sm:text-4xl font-bold mb-1 text-purple-900">
          {String(value).padStart(2, '0')}
        </div>
        <div className="text-xs sm:text-sm uppercase text-purple-700 font-semibold">{label}</div>
      </div>
    </div>
  );

  // Decorative border pattern
  const BorderPattern = () => (
    <div className="absolute inset-0 pointer-events-none">
      <div className="h-full w-full flex items-center justify-center opacity-10">
        <div className="border-2 border-amber-200 w-[98%] h-[90%] rounded-lg transform -rotate-1"></div>
        <div className="absolute border-2 border-amber-200 w-[97%] h-[85%] rounded-lg transform rotate-1"></div>
      </div>
    </div>
  );

  return (
    <div className={`relative w-full p-8 ${bgColor} rounded-xl shadow-2xl overflow-hidden`}>
      <BorderPattern />
      
      {/* Decorative corners */}
      <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-amber-200 opacity-50 rounded-tl-lg"></div>
      <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-amber-200 opacity-50 rounded-tr-lg"></div>
      <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-amber-200 opacity-50 rounded-bl-lg"></div>
      <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-amber-200 opacity-50 rounded-br-lg"></div>

      <div className="relative flex flex-col items-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 text-center font-serif tracking-wider text-amber-100">
          {name || "Launch Offer"}
        </h2>
        
        {/* Decorative line */}
        <div className="flex items-center w-64 my-4">
          <div className="flex-grow h-px bg-amber-200 opacity-50"></div>
          <div className="mx-4 text-amber-200">✽</div>
          <div className="flex-grow h-px bg-amber-200 opacity-50"></div>
        </div>
        <p className="text-sm sm:text-base mb-8 text-center text-amber-100 max-w-2xl">
  {description || (
    <>
      Get 40% flat off on your first order! Use coupon code{" "}
      <strong className=' text-xl text-purple-700 bg-amber-200 px-2'>LAUNCH40</strong> to claim the offer. Don't miss out, shop now!
    </>
  )}
</p>
        
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6 w-full max-w-3xl">
          <TimeUnit value={timeLeft.days} label="Days" />
          <TimeUnit value={timeLeft.hours} label="Hours" />
          <TimeUnit value={timeLeft.minutes} label="Minutes" />
          <TimeUnit value={timeLeft.seconds} label="Seconds" />
        </div>
      </div>
    </div>
  );
};

// Example usage with traditional Indian sale themes
const Deals = () => {
  const sales = [
    {
          endDate: "2024-12-10T23:59:59",
      bgColor: "bg-gradient-to-br from-purple-900 to-purple-800",
      textColor: "text-amber-100"
    }
    

  ];

  return (
    <div className="max-w-7xl mx-auto p-4 space-y-8">
      {sales.map((sale, index) => (
        <SareeCountdownTimer
          key={index}
          name={sale.name}
          description={sale.description}
          endDate={sale.endDate}
          bgColor={sale.bgColor}
          textColor={sale.textColor}
        />
      ))}
    </div>
  );
};

export default Deals;