// ** MUI Imports
import Grid from "@mui/material/Grid";
import AdminPannel from "../../Styles/AdminPannelWrapper";
import Achivement from "../tables/Achivement";
import MonthlyOverview from "../tables/MonthlyOverView";
import WeeklyOverview from "../tables/WeeklyOverview";
import TotalEarning from "../tables/TotalEarning";
import CardStatsVertical from "../../Styles/CardStatsVertical";
import SalesByCountries from "../tables/SalesByContry";
import DepositWithdraw from "../tables/DepositeAndWithdraw";
import CustomersTable from "../tables/CustomersTable";
import { ThemeProvider, createTheme } from "@mui/material";
import { customTheme, DashTheme } from "../them/customeThem";
import "./Admin.css";
import RecentlyAddeddProducts from "../tables/RecentlyAddeddProducts";
import SalesOverTime from "../tables/SalesOverTime";
import RecentOrders from "../tables/RecentOrders";
import {AssuredWorkloadIcon }from '@mui/icons-material';
import { BriefcaseVariantOutline, CurrencyUsd, HelpCircleOutline, Poll } from "mdi-material-ui";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const darkTheme1 = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#008fff',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});



// bg-[#28243d]
const Dashboard = () => {
  const {auth}=useSelector(store=>store);
  const navigate=useNavigate()


 
  return (
<div className="adminContainer">
  <ThemeProvider theme={DashTheme}>
    <AdminPannel>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Achivement />
        </Grid>
        <Grid item xs={12} md={8}>
          <MonthlyOverview />
        </Grid>
        
        <Grid container item xs={12} md={12} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <WeeklyOverview />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TotalEarning />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardStatsVertical
                  stats="$25.6k"
                  icon={<Poll />}
                  color="success"
                  trendNumber="+42%"
                  title="Total Profit"
                  subtitle="Weekly Profit"
                />
              </Grid>
              <Grid item xs={6}>
                <CardStatsVertical
                  stats="$78"
                  title="Refunds"
                  trend="negative"
                  color="secondary"
                  trendNumber="-15%"
                  subtitle="Past Month"
                  icon={<CurrencyUsd />}
                />
              </Grid>
              <Grid item xs={6}>
                <CardStatsVertical
                  stats="862"
                  trend="negative"
                  trendNumber="-18%"
                  title="New Orders"
                  subtitle="Weekly Orders"
                  icon={<BriefcaseVariantOutline />}
                />
              </Grid>
              <Grid item xs={6}>
                <CardStatsVertical
                  stats="15"
                  color="warning"
                  trend="negative"
                  trendNumber="-18%"
                  subtitle="Last Week"
                  title="Sales Queries"
                  icon={<HelpCircleOutline />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <SalesOverTime />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CustomersTable />
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <RecentOrders />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={8}>
          <RecentlyAddeddProducts />
        </Grid>

        <Grid item xs={12}>
          <CustomersTable />
        </Grid>
      </Grid>
    </AdminPannel>
  </ThemeProvider>
</div>

  );
};

export default Dashboard;
