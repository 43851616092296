import React from "react";
import { useLocation } from "react-router-dom";

const TopGap = () => {
  const location = useLocation();

  // Render the gap only if the current route is not "/"
  if (location.pathname === "/") {
    return null; // Render nothing on the home page
  }

  return <div className="w-full h-16"></div>;
};

export default TopGap;
