import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import RegisterUserForm from "./Register";
import { useEffect } from "react";
import LoginUserForm from "./Login";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import bgGraphics from "../../../asstes/Brand/bgGraphics.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  boxShadow: 24,
  p: 4,
};

export default function AuthModal({ handleClose, open }) {
  const location = useLocation();
  const { auth } = useSelector((store) => store);

  useEffect(() => {
    if (auth.user) handleClose();
  }, [auth.user]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="large"
      >
        <Box className="rounded-md relative" sx={style}>
          {/* Close Button */}
          <Button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 50,
              right: 50,
              zIndex:"30",
              background: "white",
              color: "grey",
              borderRadius: "50%",
              padding: "10px",
              minWidth: "unset",
              fontWeight:"bold",
              width: "60px",
              height: "60px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds a soft shadow
            }}
            
          >
            <i class="fa-solid fa-xmark  text-xl"></i>
          </Button>

          {/* Background Graphic */}
          {/* <div className="absolute top-40 right-0 w-1/2 h-full hidden md:block">
            <img
              className="w-[550px] h-auto"
              src={bgGraphics}
              alt=""
              srcSet=""
            />
          </div> */}

          {/* Form Component */}
          {location.pathname === "/register" ? (
            <RegisterUserForm />
          ) : (
            <LoginUserForm />
          )}
        </Box>
      </Modal>
    </>
  );
}
