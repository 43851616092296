import { API_BASE_URL } from '../../../config/api';
import {
    CREATE_PAYMENT_REQUEST,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,
    UPDATE_PAYMENT_REQUEST,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_FAILURE,
  } from './ActionType';
  
  import axios from 'axios';
  
  export const createPayment = (reqData) => async (dispatch) => {
    console.log("datta", reqData);
    console.log("create payment reqData ", reqData);
  
    try {
      dispatch({
        type: CREATE_PAYMENT_REQUEST,
      });
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${reqData.jwt}`,
        },
      };
  
      // Build the URL with or without the coupon_code based on its existence
      let url = `${API_BASE_URL}/api/payments/${reqData.orderId}`;
      if (reqData.appliedCouponCode) {
        url += `?coupon_code=${reqData.appliedCouponCode}`;
      }
  
      const { data } = await axios.post(url, reqData, config);
  
      if (data.payment_link_url) {
        window.location.href = data.payment_link_url;
      }
  
      dispatch({
        type: CREATE_PAYMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PAYMENT_FAILURE,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
  
  



  export const updatePayment = (reqData) => {
    return async (dispatch) => {
      console.log("update payment reqData ", reqData);
      dispatch(updatePaymentRequest());
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${reqData.jwt}`,
          },
        };
  
        // Build the URL with or without the coupon_code based on its existence
        let url = `${API_BASE_URL}/api/payments?payment_id=${reqData.paymentId}&order_id=${reqData.orderId}`;
        if (reqData.appliedCouponCode == !null) {
          url += `&coupon_code=${reqData.appliedCouponCode}`;
        }
  
        const response = await axios.get(url, config);
        console.log("updated data", response.data);
        dispatch(updatePaymentSuccess(response.data));
      } catch (error) {
        dispatch(updatePaymentFailure(error.message));
      }
    };
  };
  
export const updatePaymentRequest = () => {
  return {
    type: UPDATE_PAYMENT_REQUEST,
  };
};

export const updatePaymentSuccess = (payment) => {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    payload: payment,
  };
};

export const updatePaymentFailure = (error) => {
  return {
    type: UPDATE_PAYMENT_FAILURE,
    payload: error,
  };
};

 
  