import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Component  ,Factory  , BadgePercent , HeartHandshake  , Package , ShieldCheck } from 'lucide-react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

const promises = [
  {
    icon: <Component className=' text-[#AC9969] w-16 h-16' />,
    title: 'Exclusive Design',
    description: 'Uniquely crafted by artisans for a one-of-a-kind piece.',
  },
  {
    icon: <Factory className=' text-[#AC9969] w-16 h-16' />,
    title: 'Directly from Manufacture',
    description: 'Get jewelry straight from our factory, with no middlemen.',
  },
  {
    icon: <BadgePercent className=' text-[#AC9969] w-16 h-16' />,
    title: 'Best Price Challenge',
    description: 'We offer the best prices—find a better one, and we’ll match it.',
  },
  {
    icon: <HeartHandshake className=' text-[#AC9969] w-16 h-16' />,
    title: 'Hand Crafted with Love',
    description: 'Crafted by skilled artisans with passion and care.',
  },
  {
    icon: <Package className=' text-[#AC9969] w-16 h-16' />,
    title: 'Fast Delivery',
    description: 'Your orders will reach you swiftly and securely.',
  },

  {
    icon: <ShieldCheck className=' text-[#AC9969] w-16 h-16' />,
    title: 'Secure Transactions',
    description: 'Your information is safe with us.',
  },

];

const OurPromises = () => {
  return (
    <div className="py-10 bg-[#fdfcfa] ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl Head font-bold  text-gray-900">Kalaksha Promises</h2>
          <p className="mt-4 text-lg SubHead  text-gray-600">The promises that we'll never break</p>
        </div>

        <Swiper
          modules={[Autoplay]}
          spaceBetween={10} // Space between slides
          slidesPerView={1} // Default number of slides visible
          loop={true} // Infinite loop
          autoplay={{
            delay: 1500, // Delay between transitions
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: { slidesPerView: 1 }, // Show 3 slides on very small screens
            768: { slidesPerView: 3 }, // Show 3 slides on tablets
            1024: { slidesPerView: 4 }, // Show 3 slides on medium screens
            1280: { slidesPerView: 4 }, // Show 4 slides on large screens
          }}
        >
          {promises.map((promise, index) => (
            <SwiperSlide key={index}>
              <div className=" px-4 w-[250px]  rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-300 h-[250px] flex flex-col  justify-evenly items-start">
                <div className="text-4xl  mt-3">{promise.icon}</div>
                <h3 className="text-xs sm:text-lg  text-left font-semibold text-gray-900">{promise.title}</h3>
                <p className="text-gray-500 mb-4 text-[8px] Para md:text-[15px] lg:text-[13px] text-left">{promise.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurPromises;
