import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
  ShoppingCartIcon
} from "@heroicons/react/24/outline";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { navigation } from "../../../config/navigationMenu";
import AuthModal from "../Auth/AuthModal";
import { useDispatch, useSelector } from "react-redux";
import { deepPurple } from "@mui/material/colors";
import { getUser, logout } from "../../../Redux/Auth/Action";

// import { getCart } from "../../../Redux/Customers/Cart/Action";
import TextField from "@mui/material/TextField";
import Mainlogo from "../logo/Mainlogo";
import { selectCartItemCount } from "../../../Redux/Customers/Cart/Reducer";
import AnnouncementBar from "./AnnouncementBar";
import { getCart } from "../../../config/api";
import { CartPopup } from "./Popcart";
import { Heart, UserRound } from "lucide-react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, cart } = useSelector((store) => store);
  const [cartData, setCartData] = useState({ totalItem: 0 });
  const [Popup, setPopup] = useState(false);
  const [newitem, setNewitem] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const jwt = localStorage.getItem("jwt");
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(true); // State to track scroll condition

  useEffect(() => {
    if (location.pathname === "/") {
      setIsScrolled(false);
    }
  }, [location.pathname, setIsScrolled]);

  const products = [
    {
      id: 1,
      name: 'Throwback Hip Bag',
      href: '#',
      color: 'Salmon',
      price: '$90.00',
      quantity: 1,
      imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/shopping-cart-page-04-product-01.jpg',
      imageAlt: 'Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt.',
    },
    {
      id: 2,
      name: 'Medium Stuff Satchel',
      href: '#',
      color: 'Blue',
      price: '$32.00',
      quantity: 1,
      imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/shopping-cart-page-04-product-02.jpg',
      imageAlt:
        'Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.',
    },
    // More products...
  ]

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };
  const getLastAddedItem = () => {
    const lastItem = cartData.cartItems[cartData.cartItems.length - 1];
    setNewitem(lastItem)
    console.log(newitem)
  };
  useEffect(() => {
    const poptest = localStorage.getItem('popcartnew');
    if (poptest) {
      setPopup(true);
    } else {
      setPopup(false);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {

      try {
        const data = await getCart(jwt);
        setCartData(data);
        getLastAddedItem()// Check if popcartnew is true in localStorage

        // if (poptest) {
        //   setPopup(true);
        // }

        setLoading(false);
      } catch (err) {
        setError(err.message || 'Something went wrong');
        setLoading(false);
      }
    };

    fetchData();
  }, [cart]);
  const ClosePopup = () => {
    setPopup(false);
  };
  useEffect(() => {
    if (Popup) {
      const timer = setTimeout(() => {
        ClosePopup();
      }, 3000); // Close after 3 seconds

      return () => clearTimeout(timer); // Cleanup on unmount or when popup is closed
    }
  }, [Popup]);



  console.log("acart", cartData.totalItem)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Check if the path is "/" and scroll position > 20
      if (scrollY > 20 && location.pathname === "/") {
        setIsScrolled(true);
      } else if (location.pathname === "/") {
        setIsScrolled(false);
      }
    };


    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the listener
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);




  // useEffect(() => {
  //   if (jwt) {
  //     dispatch(getUser(jwt));
  //     dispatch(getCart(jwt));
  //   }
  // }, [jwt]);

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenAuthModal(true);
  };
  const handleClose = () => {
    setOpenAuthModal(false);
  };

  const handleCategoryClick = (category, section, item, close) => {
    navigate(`/${category.id}/${section.id}/${item.id}`);
    close();
  };

  useEffect(() => {
    if (auth.user) {
      handleClose();
    }
    if (location.pathname === "/login" || location.pathname === "/register") {
      navigate(-1);
    }
  }, [auth.user]);

  const handleLogout = () => {
    handleCloseUserMenu();
    dispatch(logout());
  };
  const handleMyOrderClick = () => {
    handleCloseUserMenu();
    auth.user?.role === "ROLE_ADMIN"
      ? navigate("/admin")
      : navigate("/account/order");
  };
  //  console.log(cartData)



  return (
    <div className="bg-white ">

      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-button-green">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-button-green subhead text-button-green"
                                : "border-transparent text-gray-900",
                              "flex-1 whitespace-nowrap  px-1 py-4 text-base font-medium border-none"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-10 px-4 pb-8 pt-10"
                      >
                        <div className="grid grid-cols-2 gap-x-4">
                          {category.featured.map((item) => (
                            <div
                              key={item.name}
                              className="group relative text-sm"
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={item.href}
                                className="mt-6 block font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p aria-hidden="true" className="mt-1">
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div>
                        {category.sections.map((section) => (
                          <div key={section.name}>
                            <p
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className="font-medium text-gray-900"
                            >
                              {section.name}
                            </p>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="mt-6 flex flex-col space-y-6"
                            >
                              {section.items.map((item) => (
                                <li key={item.name} className="flow-root">
                                  <p className="-m-2 block p-2 text-gray-500">
                                    {item.name}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t SubHead border-gray-200 px-4 py-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className={` w-full bg-gradient-to-r from-[#f2ebda] to-[#f8f5ed] shadow-lg z-50 ease-in-out ${isScrolled ? "fixed" : "relative"}`}>
        <AnnouncementBar />


        <nav aria-label="Top" className="mx-auto bg">
          <div className=" flex justify-center items-center">
            <div className="flex  w-full lg:max-w-7xl justify-center h-16 items-center ml-4">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-button-green lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6 " aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className=" flex justify-between items-center w-full h-full">



                <div className=" flex ">
                  <Link to="/">
                    <span className="sr-only">Your Company</span>
                    <h1 className={`text-3xl  text-button-green Head font-bold lg:text-button-green}`}>KALAKSHA</h1>
                  </Link>
                </div>


               {/* Nav Links */}
                <div className=" w-full h-auto lg:px-4 py-4  flex justify-center items-center">
                  <Popover.Group className="hidden  lg:block lg:self-stretch z-40">
                    <div className="flex h-full space-x-8">
                      {navigation.categories.map((category) => (
                        <Popover key={category.name} className="flex">
                          {({ open, close }) => (
                            <>
                              <div className="relative  flex">
                                <Popover.Button
                                  className={classNames(
                                    open
                                      ? `text-gray-600`
                                      : `border-transparent text-gray-600  hover:scale-110 hover:text-button-green`,
                                    "relative z-10 SubHead uppercase font-medium -mb-px flex items-center border-b-2 pt-px text-sm  transition-colors duration-200 ease-out"
                                  )}
                                >
                                  {category.name}
                                </Popover.Button>   
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                                  {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                  <div
                                    className="absolute inset-0 top-1/2 bg-white shadow"
                                    aria-hidden="true"
                                  />

                                  <div className="relative bg-[#ffede1] z-50">
                                    <div className="mx-auto max-w-7xl px-8">
                                      <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                                        <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                          {category.featured.map((item) => (
                                            <div
                                              key={item.name}
                                              className="group relative text-base sm:text-sm"
                                            >
                                              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                <img
                                                  src={item.imageSrc}
                                                  alt={item.imageAlt}
                                                  className="object-cover object-center"
                                                />
                                              </div>
                                              <a
                                                href={item.href}
                                                className="mt-6 block font-medium text-gray-900"
                                              >
                                                <span
                                                  className="absolute inset-0 z-10"
                                                  aria-hidden="true"
                                                />
                                                {item.name}
                                              </a>
                                              <p
                                                aria-hidden="true"
                                                className="mt-1"
                                              >
                                                Shop now
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                        <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm">
                                          {category.sections.map((section) => (
                                            <div key={section.name}>
                                              <p
                                                id={`${section.name}-heading`}
                                                className="font-medium text-gray-900"
                                              >
                                                {section.name}
                                              </p>
                                              {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                              <ul
                                                role="list"
                                                aria-labelledby={`${section.name}-heading`}
                                                className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                              >
                                                {section.items.map((item) => (
                                                  <li
                                                    key={item.name}
                                                    className="flex"
                                                  >
                                                    <p
                                                      onClick={() =>
                                                        handleCategoryClick(
                                                          category,
                                                          section,
                                                          item,
                                                          close
                                                        )
                                                      }
                                                      className="cursor-pointer hover:text-gray-800"
                                                    >
                                                      {item.name}
                                                    </p>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      ))}

                      {navigation.pages.map((page) => (
                        <a
                          key={page.name}
                          href={page.href}
                          className={`flex SubHead uppercase items-center text-sm cursor-pointer font-medium transition-all ease-in-out  text-gray-600 hover:scale-110 hover:text-button-green`}
                        >
                          {page.name}
                        </a>
                      ))}
                    </div>
                  </Popover.Group>
                </div>

                <div className=" flex items-center">


                  {/* Search */}
                  {/* <div className="flex items-center mr-[-18px] lg:mr-0 lg:ml-6">

                    <p onClick={() => navigate("/products/search")} className="p-2 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Search</span>

                      <MagnifyingGlassIcon
                        className={`h-6 w-6 ${isScrolled ? " text-gray-600" : " text-white"}`}
                        aria-hidden="true"
                      />
                    </p>
                  </div> */}

                  {/* Cart */}
                  <div className="flow-root ">

                    
                    <Button
                      onClick={() => navigate("/cart")}
                      className="group -m-2 flex items-center p-2"
                    >
                      {showPopup && (
                        <popup products={products} />
                      )}

                      <ShoppingBagIcon
                        className={`h-6 w-6  mr-2 flex-shrink-0 text-gray-600`}
                        aria-hidden="true"
                      />


                      <span className="text-sm font-medium text-white rounded-full bg-button-green  bottom-[-5px]  absolute p-[10px] flex justify-center items-center w-4 h-4 group-hover:text-gray-800">
                        {cartData.totalItem}
                      </span>


                      <span className="sr-only">items in cart, view bag</span>
                    </Button>
                  </div>

              <div className=" flow-root ">                  
              <Button
                onClick={() => navigate("/cart")}
                className="group -m-2 flex items-center p-2"
              >
                {showPopup && (
                  <popup products={products} />
                )}

              <Heart size={16}  className={`h-6 w-6 flex-shrink-0  text-gray-600 `} strokeWidth={1} absoluteStrokeWidth aria-hidden="true" />
   
                <span className="text-sm font-medium text-white rounded-full bg-red-600 bottom-[-5px]  absolute p-[10px] flex justify-center items-center w-4 h-4 group-hover:text-gray-800">
                  {cartData.totalItem}
                </span>
              </Button>
              </div>
                  <a href="/account/wishlist">

                      </a>

                  {/* User */}
                  <div className=" mr-[20px] lg:mr-[60px] lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    {auth.user ? (
                      <div>
                        <Avatar
                          className="text-white"
                          onClick={handleUserClick}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // onClick={handleUserClick}
                          sx={{
                            bgcolor: "#006655",
                            color: "button-green",
                            cursor: "pointer",
                          }}
                        >
                          {auth.user?.firstName[0].toUpperCase()}
                        </Avatar>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openUserMenu}
                          onClose={handleCloseUserMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleMyOrderClick}>
                            {auth.user?.role === "ROLE_ADMIN"
                              ? "Admin Dashboard"
                              : "My Orders"}
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                          {/* <MenuItem onClick={handleLogout}>Wishlist</MenuItem>
                        <MenuItem onClick={handleLogout}>Wishlist</MenuItem> */}
                        </Menu>
                      </div>
                    ) : (
                      <button onClick={handleOpen} className=" ml-1 font-medium  rounded-sm px-3 py-1  text-button-green ">

                        <UserRound size={36} className={` text-gray-600`} strokeWidth={1.25} />

                      </button>
                      // <Button
                      //   onClick={handleOpen}
                      //   className=" bg-button-green text-white "
                      // >
                      //   Signin
                      // </Button>
                    )}
                  </div>


                </div>

              </div>

              {/* Flyout menus */}


            </div>
          </div>
        </nav>

      </header>
      <AuthModal handleClose={handleClose} open={openAuthModal} />
      {Popup && <CartPopup item={newitem} cartq={cartData.totalItem} onClose={ClosePopup} />}
    </div>
  );
}
